import useAuth from 'hooks/useAuth';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Loader from './sharedComponents/Loader';

function RestrictedRoute({ component: Component, ...rest }) {
  const isLoggedIn = useAuth();
  const query = new URLSearchParams(useLocation().search);
  const embedSignup = query.get('embed');

  if (isLoggedIn === null && !embedSignup) {
    return <Loader show={true} />;
  }

  return !isLoggedIn ? (
    <Component {...rest} />
  ) : !embedSignup ? (
    <Navigate to={'/pages'} />
  ) : (
    <Component {...rest} />
  );
}

export default RestrictedRoute;
