import { checkNeedMessageBeforeReload } from 'helpers/actionsBeforeClosePage';
import { getDeviceTimeZone } from 'helpers/time';
import { lazy, Suspense, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import smartlookClient from 'smartlook-client';
import { getUser, saveGoogleEvents, updateUserTimeZone } from 'store/reducers/userReducer';
import { authDataSelector, userDataSelector } from 'store/selectors';
import { v4 as uuidv4 } from 'uuid';
import PrivateRoute from './PrivateRoute';
import RestrictedPublicRoute from './RestrictedPublicRoute';

import 'assets/vendor/quill/dist/quill.snow.css';
import './App.scss';

/* single pages */
const Main = lazy(() => import('./content/pages/Main'));
const Edit = lazy(() => import('./content/pages/edit/Edit'));
const SingleSession = lazy(() => import('./content/pages/Session'));
const SingleMeeting = lazy(() => import('./content/pages/Meeting'));
const Upgrade = lazy(() => import('./content/pages/Upgrade'));
const Upgraded = lazy(() => import('./content/pages/dashboard/Upgraded'));
const UnpaidInvoices = lazy(() => import('./content/pages/UnpaidInvoices'));
const PrivacyPolicy = lazy(() => import('content/pages/PrivacyPolicy'));
const SecurityHub = lazy(() => import('content/pages/SecurityHub'));
const SecurityPolicy = lazy(() => import('content/pages/SecurityPolicy'));
const VulnerabilityManagementPolicy = lazy(() => import('content/pages/VulnerabilityManagementPolicy'));
const EncryptionPolicy = lazy(() => import('content/pages/EncryptionPolicy'));
const IncidentManagementPolicy = lazy(() => import('content/pages/IncidentManagementPolicy'));
const InformationManagementPolicy = lazy(() => import('content/pages/InformationManagementPolicy'));
const TermsOfUse = lazy(() => import('content/pages/TermsOfUse'));
const Loader = lazy(() => import('content/pages/Loader'));
const Changeusername = lazy(() => import('./content/pages/onboarding/Changeusername'));
const ConnectAccount = lazy(() => import('./content/pages/onboarding/ConnectAccount'));

/* auth pages */
const Auth = lazy(() => import('./content/pages/Auth'));
const InviteLink = lazy(() => import('./sharedComponents/auth/signup/InviteLink'));
const GoogleAuth = lazy(() => import('./sharedComponents/auth/GoogleAuth'));
const ChangePasswordLink = lazy(() => import('sharedComponents/auth/login/ChangePasswordLink'));
const ChangePassword = lazy(() => import('sharedComponents/auth/login/ChangePassword'));
const Confirmemail = lazy(() => import('./content/pages/onboarding/Confirmemail'));
const Confirmusername = lazy(() => import('./content/pages/onboarding/Confirmusername'));
const DescribeYourself = lazy(() => import('./content/pages/onboarding/DescribeYourself'));
const Addname = lazy(() => import('./content/pages/onboarding/Addname'));
const Tour = lazy(() => import('./content/pages/onboarding/Tour'));
const Tour2 = lazy(() => import('./content/pages/onboarding/Tour2'));
const Tour3 = lazy(() => import('./content/pages/onboarding/Tour3'));

/* meeting creation pages */
const AddMeetingCover = lazy(() => import('./content/pages/meeting/AddCover'));
const Meetings = lazy(() => import('./content/pages/dashboard/Meetings'));
const AddType = lazy(() => import('./content/pages/meeting/AddType'));
const AddTitle = lazy(() => import('./content/pages/meeting/AddTitle'));
const AddOptions = lazy(() => import('./content/pages/meeting/AddOptions'));
const AddLocation = lazy(() => import('./content/pages/meeting/AddLocation'));
const AddCustomLocation = lazy(() => import('./content/pages/meeting/AddCustomLocation'));
const ConnectZoom = lazy(() => import('./content/pages/meeting/ConnectZoom'));
const AddInPersonLocation = lazy(() => import('./content/pages/meeting/AddInPersonLocation'));
const CustomSolution = lazy(() => import('./content/pages/meeting/CustomSolution'));
const AddParticipantsNumber = lazy(() => import('./content/pages/meeting/AddParticipantsNumber'));
const AddDuration = lazy(() => import('./content/pages/meeting/AddDuration'));
const AddPrice = lazy(() => import('./content/pages/meeting/AddPrice'));
const AddAvailableHours = lazy(() => import('./content/pages/meeting/AddAvailableHours'));
const Share = lazy(() => import('./content/pages/meeting/Share'));

/**
 *@deprecated, no longer in use
 */
// const AddCustomLocation = lazy(() => import('./content/pages/meeting/AddCustomLocation'));


/* dashboard pages */
const Dashboard = lazy(() => import('./content/pages/dashboard/Dashboard'));
const Analytics = lazy(() => import('./content/pages/dashboard/Analytics'));
const Payouts = lazy(() => import('./content/pages/dashboard/Payouts'));
const Messages = lazy(() => import('./content/pages/dashboard/Messages'));
const Calendar = lazy(() => import('./content/pages/dashboard/Calendar'));
const Customers = lazy(() => import('./content/pages/dashboard/Customers'));
const Settings = lazy(() => import('./content/pages/dashboard/Settings'));
const Profile = lazy(() => import('./content/pages/dashboard/Profile'));

const Page404 = lazy(() => import('content/pages/404'));

const routes = [
  /* single routes */
  { path: '/edit/:meetingId', component: Edit, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/:username/:slug', component: SingleMeeting, isPrivateRoute: false, end: false, id: uuidv4() },
  { path: '/home', component: Main, isPrivateRoute: false, end: false, id: uuidv4() },
  { path: '/upgrade', component: Upgrade, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/upgraded', component: Upgraded, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/unpaid', component: UnpaidInvoices, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/loader', component: Loader, isPrivateRoute: false, end: false, id: uuidv4() },
  { path: '/changeusername', component: Changeusername, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/connectaccount', component: ConnectAccount, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/privacy-policy', component: PrivacyPolicy, isPrivateRoute: false, end: false, id: uuidv4() },
  { path: '/security', component: SecurityHub, isPrivateRoute: false, end: false, id: uuidv4() },
  { path: '/security-policy', component: SecurityPolicy, isPrivateRoute: false, end: false, id: uuidv4() },
  {
    path: '/vulnerability-management-policy',
    component: VulnerabilityManagementPolicy,
    isPrivateRoute: false,
    end: false,
    id: uuidv4(),
  },
  { path: '/encryption-policy', component: EncryptionPolicy, isPrivateRoute: false, end: false, id: uuidv4() },
  {
    path: '/incident-management-policy',
    component: IncidentManagementPolicy,
    isPrivateRoute: false,
    end: false,
    id: uuidv4(),
  },
  {
    path: '/information-management-policy',
    component: InformationManagementPolicy,
    isPrivateRoute: false,
    end: false,
    id: uuidv4(),
  },
  { path: '/terms-and-conditions', component: TermsOfUse, isPrivateRoute: false, end: false, id: uuidv4() },
  { path: '/session', component: SingleSession, isPrivateRoute: true, end: false, id: uuidv4() },

  /* auth routes */
  { path: '/', component: Auth, isPrivateRoute: false, end: false, id: uuidv4(), restricted: true },
  { path: '/signup', component: Auth, isPrivateRoute: false, end: false, id: uuidv4(), restricted: true },
  { path: '/login', component: Auth, isPrivateRoute: false, end: false, id: uuidv4(), restricted: true },
  { path: '/invite/:registrationToken', component: InviteLink, isPrivateRoute: false, end: true, id: uuidv4() },
  { path: '/googleAuth', component: GoogleAuth, isPrivateRoute: false, end: true, id: uuidv4() },
  {
    path: '/changePassword',
    component: ChangePassword,
    isPrivateRoute: false,
    end: true,
    id: uuidv4(),
    restricted: true,
  },
  { path: '/restorePassword/:token', component: ChangePasswordLink, isPrivateRoute: false, end: false, id: uuidv4() },
  { path: '/confirmemail', component: Confirmemail, isPrivateRoute: false, end: false, id: uuidv4() },
  { path: '/confirmusername', component: Confirmusername, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/addname', component: Addname, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/describeyourself', component: DescribeYourself, isPrivateRoute: true, end: false, id: uuidv4() },

  /* meeting creation pages */
  { path: '/pages', component: Meetings, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/tour', component: Tour, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/tour2', component: Tour2, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/tour3', component: Tour3, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/addtype', component: AddType, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/addtitle', component: AddTitle, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/addoptions', component: AddOptions, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/addlocation', component: AddLocation, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/zoom-auth', component: ConnectZoom, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/addinpersonlocation', component: AddInPersonLocation, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/customsolution', component: CustomSolution, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/addparticipants', component: AddParticipantsNumber, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/addduration', component: AddDuration, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/addprice', component: AddPrice, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/addavailablehours', component: AddAvailableHours, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/share', component: Share, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/addcover', component: AddMeetingCover, isPrivateRoute: true, end: false, id: uuidv4() },
  // { path: '/availablehoursadvanced', component: AvailableHoursAdvanced, isPrivateRoute: false, end: false, id: uuidv4()  }, //route will be add on next version

  /* dashboard routes */
  { path: '/dashboard', component: Dashboard, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/analytics', component: Analytics, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/payouts', component: Payouts, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/messages', component: Messages, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/calendar', component: Calendar, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/customers', component: Customers, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/settings', component: Settings, isPrivateRoute: true, end: false, id: uuidv4() },
  { path: '/:userName', component: Profile, isPrivateRoute: false, end: false, id: uuidv4() },

  /* 404 page */
  { path: '*', component: Page404, isPrivateRoute: false, end: true, id: uuidv4() },
];

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const userData = useSelector(userDataSelector);
  const authData = useSelector(authDataSelector);
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const token = localStorage.getItem('token');
    // const refreshTokenJwt = localStorage.getItem('refreshToken');

    if (token) {
      batch(() => {
        dispatch(getUser());
        dispatch(saveGoogleEvents());
        dispatch(updateUserTimeZone(getDeviceTimeZone()));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkNeedMessageBeforeReload(location.pathname, authData.googleConnectInProgress);
  }, [authData.googleConnectInProgress, location]);

  useEffect(() => {
    if (counter === 0) {
      smartlookClient.init('1e3a09eb614aabd4809cf129289a802ff2e2f4e9');
      setCounter(1);
    }
  }, [counter, dispatch, userData.user?.primaryEmail]);

  const query = new URLSearchParams(location.search);
  const paramsType = query.get('type');
  const paramsTypeCSS = `body{background-color: transparent !important;}}`;

  return (
    <Suspense fallback={paramsType && <style>{paramsTypeCSS}</style>}>
      <Routes>
        {routes.map(({ path, component: RouteComponent, isPrivateRoute, end, id, restricted = false }) => {
          const publicRoute = restricted ? <RestrictedPublicRoute component={RouteComponent} /> : <RouteComponent />;
          return (
            <Route
              key={id}
              path={path}
              end={end}
              element={isPrivateRoute ? <PrivateRoute component={RouteComponent} /> : publicRoute}
            />
          );
        })}
      </Routes>
    </Suspense>
  );
}

export default App;
