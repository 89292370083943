import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userDataSelector } from 'store/selectors';

function usePlan() {
  const userSelector = useSelector(userDataSelector);
  const { user } = userSelector;
  const isTrialPeriod = user.plan.subscriptionStatus === 'trialing';
  const isActivePlan = user.plan.subscriptionStatus === 'active' || isTrialPeriod;

  const isFreePlan = useMemo(() => isActivePlan && !user.plan.subscriptionId, [isActivePlan, user.plan.subscriptionId]);

  const isBasicUserPlan = user.plan.name === 'basic';
  const isProUserPlan = user.plan.name === 'pro';
  const isMonthUserPlan = user.plan.interval === 'month';
  const isYearUserPlan = user.plan.interval === 'year';

  return { isFreePlan, isActivePlan, isBasicUserPlan, isProUserPlan, isMonthUserPlan, isYearUserPlan, isTrialPeriod };
}

export default usePlan;
