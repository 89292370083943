import { defaultDuration } from 'constants/meeting.const';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';

const SECOND_IN_MINUTES = 60;
const MS_IN_SECOND = 1000;

export const ALL_DAY = {
  HOURS: 24,
  MINUTES: 1440,
  SECONDS: 86400,
};

export const addOneHourRounded = (date) => {
  const currentTime = date.getTime();

  const nextHour = new Date(currentTime + SECOND_IN_MINUTES * SECOND_IN_MINUTES * MS_IN_SECOND);

  const minutes = nextHour.getMinutes();
  const roundedMinutes = minutes <= 30 ? 0 : 30;
  nextHour.setMinutes(roundedMinutes);

  return nextHour;
};

export const addHalfHour = (date) => new Date(new Date(date).getTime() + 30 * SECOND_IN_MINUTES * MS_IN_SECOND);

export const convertToHours = (hours) => hours * (SECOND_IN_MINUTES * SECOND_IN_MINUTES * MS_IN_SECOND); // (minutes*seconds*milliseconds)

export const DAYS_IN_MONTH = 60;

export const convertFormatterArrayToObject = (arr) =>
  arr.reduce(
    (a, v) => ({
      ...a,
      [v.type]: v.value,
    }),
    {},
  );

export const getDateInFormat_DD_MM_YYYY = (date) => {
  return moment(date).format('DD/MM/YYYY');
};
export const getDateInFormat_MMM_DD_YYYY = (date) => {
  return moment(date).format('D MMM YYYY [at] h:mmaz');
};

const getDayStrFromDate = (date) => {
  return new Date(date).toISOString().substring(0, 10);
};

export const getMilliseconds = (date) => new Date(date).getTime();

export const sortDatesByMs = (datesArray) => datesArray.sort((a, b) => a - b);

export const getMillisecondsDiff = (firstDate, secondDate) => {
  return new Date(firstDate) - new Date(secondDate);
};

const getTimeStrFromDate = (date) => {
  return new Date(date).toISOString().substring(10, 24);
};

export const convertToGoogleDateFormat = ({ fromTime, toTime, date }) => {
  const regExTakeAllFormatSymbInDate = /-|:|\.\d+/g;
  const googleFromTime = getTimeStrFromDate(new Date(fromTime)).replace(regExTakeAllFormatSymbInDate, '');
  const googleToTime = getTimeStrFromDate(new Date(toTime)).replace(regExTakeAllFormatSymbInDate, '');
  const googleDate = getDayStrFromDate(new Date(date)).replace(regExTakeAllFormatSymbInDate, '');
  // googleCalendar expect 20220207T110001Z/20220207T114501Z dates format
  return `${googleDate}${googleFromTime}/${googleDate}${googleToTime}`;
};

export const convertToOutlookDateFormat = ({ fromTime, toTime, date }) => {
  const outlookFromTime = getTimeStrFromDate(new Date(fromTime));
  const outlookToTime = getTimeStrFromDate(new Date(toTime));
  const outlookDate = getDayStrFromDate(new Date(date));
  const startdt = `${outlookDate}${outlookFromTime}`;
  const enddt = `${outlookDate}${outlookToTime}`;
  // outlookCalendar expect 2022-02-07T13:00:01.001Z startdt and enddt format
  return { startdt, enddt };
};

export const getDateInFormat_ddd_D_MMMM = (date) => {
  const dateObj = new Date(date);
  const formatter = new Intl.DateTimeFormat('en-us', {
    weekday: 'short',
    month: 'long',
    day: 'numeric',
  });
  const { weekday, month, day } = convertFormatterArrayToObject(formatter.formatToParts(dateObj));
  return `${weekday}, ${month} ${day}`;
};

export const getTimeFromTimezone = (date, tzString) => {
  const newDate = new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString }),
  );

  let hours = newDate.getHours();
  let minutes = newDate.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return hours + ':' + minutes + ' ' + ampm;
};

export const getMeetingDuration = (meeting) => {
  return meeting?.meetingPackages.find((el) => el.idName === 'basic').sessionTime;
};

export function getMinutesBetweenDates(startDate, endDate) {
  var diff = endDate.getTime() - startDate.getTime();
  return diff / 60000;
}

export const getNameOfDay = (date) => {
  return moment(date).format('dddd');
};

export const getNameOfMonth = (monthIndex) => {
  let date = new Date();
  date.setMonth(monthIndex);

  return moment(date).format('MMMM');
};

export const getMonthIndexByNameOfMonth = (nameOfMonth) => {
  return Number(moment().month(nameOfMonth).format('M')) - 1;
};

export const getAvailableDaysInMonth = (date) => {
  const numberOfMonth = +moment(date).format('DD');
  let numberOfAvailableDays = +moment(date).daysInMonth();

  if (date.getMonth() === new Date().getMonth()) {
    numberOfAvailableDays -= numberOfMonth - 1;
  }

  return new Array(numberOfAvailableDays).fill('').map((_, i) => numberOfMonth + i);
};

export const getAvailableMonthsInYear = (year) => {
  let availableMonth = 11;
  let currentMonth = 0;

  if (year === new Date().getFullYear()) {
    currentMonth = new Date().getMonth();
    availableMonth -= currentMonth;
  }

  return new Array(availableMonth + 1).fill('').map((_, i) => getNameOfMonth(currentMonth + i));
};

export const getAvailableYears = (year) => {
  return new Array(15).fill('').map((_, i) => year + i);
};

export const convertMeetingDurationToMinutes = (meetingDuration) => {
  return checkTime(meetingDuration) !== 'minutes' ? meetingDuration * 60 : meetingDuration;
};

/*
format for time => '15:00', '12:12', '05:31'...
format for period => 'am' or 'pm'
 */
export const convertToMinutes = (time, period) => {
  const hours = +time.split(':')[0];
  const minutes = +time.split(':')[1];

  if (hours === 12 && period === 'am') {
    return minutes;
  }

  if (hours === 12 && period === 'pm') {
    return 12 * 60 + minutes;
  }

  const minutesForAM = hours * 60 + minutes;
  const minutesForPM = minutesForAM + 12 * 60;

  return period.toLowerCase() === 'am' ? minutesForAM : minutesForPM;
};

export const convertFromMinutesToAmPmFormat = (minutes, nameOfProperty = 'time') => {
  let hours = Math.floor(minutes / 60);
  let mins = minutes % 60;

  const period = hours < 12 || hours >= 24 ? 'am' : 'pm';

  hours = hours % 12;
  hours = hours ? hours : 12;

  if (hours === 12 && period === 'am') {
    hours = 12;
  }

  mins = mins < 10 ? '0' + mins : mins;

  return {
    [nameOfProperty]: hours + ':' + mins,
    period,
  };
};

export const checkTime = (time) => {
  if (time < 10 && time !== 1) return 'hours';
  if (time === 1) return 'hour';
  return 'minutes';
};

export const sortTimeFromMorningToEvening = (timeArr, format = 'amPm') => {
  if (isEmpty(timeArr)) {
    return timeArr;
  }

  return cloneDeep(timeArr).sort((timeEl1, timeEl2) => {
    if (format === 'date') {
      return new Date(timeEl1.to) < new Date(timeEl2.to) ? -1 : 1;
    }

    return convertToMinutes(timeEl1.to.hour, timeEl1.to.period) < convertToMinutes(timeEl2.to.hour, timeEl2.to.period)
      ? -1
      : 1;
  });
};

export const timesCompare = (time1, time2) => {
  return convertToMinutes(time1.hour, time1.period) < convertToMinutes(time2.hour, time2.period);
};

const isTimesOverlap = (newTime, arrayOfTimes) => {
  let newFromInMinutes = convertToMinutes(newTime.from.hour, newTime.from.period);
  let newToInMinutes = convertToMinutes(newTime.to.hour, newTime.to.period);

  if (newFromInMinutes > newToInMinutes) {
    [newFromInMinutes, newToInMinutes] = [newToInMinutes, newFromInMinutes];
  }

  const timesOverlaps = arrayOfTimes.filter((item) => {
    if (item.id === newTime.id) return false;

    const oldFromInMinutes = convertToMinutes(item.from.hour, item.from.period);
    const oldToInMinutes = convertToMinutes(item.to.hour, item.to.period);

    if (newFromInMinutes < oldFromInMinutes && newToInMinutes > oldFromInMinutes) return true;
    if (newFromInMinutes < oldToInMinutes && newToInMinutes > oldToInMinutes) return true;

    if (oldFromInMinutes < newFromInMinutes && oldToInMinutes > newToInMinutes) return true;
    if (oldFromInMinutes < newToInMinutes && oldToInMinutes > newToInMinutes) return true;

    return false;
  });

  if (!isEmpty(timesOverlaps)) {
    return true;
  } else {
    return false;
  }
};

export const validationTimes = (arrayOfTimes) => {
  const error = {
    errorWrongTime: [],
    overlapTimes: [],
  };
  arrayOfTimes.forEach((item, _, array) => {
    if (!timesCompare(item.from, item.to)) {
      error.errorWrongTime.push(item.id);
    }
    if (isTimesOverlap(item, array)) {
      error.overlapTimes.push(item.id);
    }
  });
  return error;
};

export const checkOrdersOverlapping = (order1, order2) => {
  if (order1.id === order2.id) return false;

  if (isSameDay(order1.start, order2.end)) {
    const order1FromInMinutes = new Date(order1.start);
    const order1ToInMinutes = new Date(order1.end);
    const order2FromInMinutes = new Date(order2.start);
    const order2ToInMinutes = new Date(order2.end);

    if (order1FromInMinutes < order2FromInMinutes && order1ToInMinutes > order2FromInMinutes) return true;
    if (order1FromInMinutes < order2ToInMinutes && order1ToInMinutes > order2ToInMinutes) return true;

    if (order2FromInMinutes < order1FromInMinutes && order2ToInMinutes > order1ToInMinutes) return true;
    if (order2FromInMinutes < order1ToInMinutes && order2ToInMinutes > order1ToInMinutes) return true;
  }
  return false;
};

export const isErrorsWithAvailableHoursValidation = (errors) => {
  if (isEmpty(errors)) return false;

  const errorsStatus = [];
  for (const err of Object.values(errors)) {
    if (err?.errorWrongTime?.length || err?.overlapTimes?.length) {
      errorsStatus.push('errors');
    }
  }

  if (!errorsStatus.length) return false;
  return true;
};

export const setSecondsToDefault = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return new Date(year, month, day, hours, minutes, 1, 1);
};

export const isSameDay = (date1, date2) => {
  const date1Format = new Date(date1);
  const date2Format = new Date(date2);
  return (
    date1Format.getFullYear() === date2Format.getFullYear() &&
    date1Format.getMonth() === date2Format.getMonth() &&
    date1Format.getDate() === date2Format.getDate()
  );
};

export const setHoursAndMinutesToDay = (date, time) => {
  const dateWithHours = new Date(date.setHours(time.getHours()));
  return new Date(dateWithHours.setMinutes(time.getMinutes()));
};

export const makeDateFromDayAndAmPmFormat = (AmPm, date) => {
  const time = new Date(convertFromAmPmFormatToDate(AmPm));
  const dayWithHoursAndMinutes = setHoursAndMinutesToDay(date, time);
  return setSecondsToDefault(dayWithHoursAndMinutes);
};

export const getOffsetBetweenTimeZones = (a, b) => {
  const timeZoneA = new Date().toLocaleString('en-US', { timeZone: a });
  const timeZoneB = new Date().toLocaleString('en-US', { timeZone: b });
  const offsetInMs = new Date(timeZoneB).getTime() - new Date(timeZoneA).getTime();
  return Math.sign(offsetInMs) === -1 ? Math.floor(Math.abs(offsetInMs) / 60000) : Math.ceil(offsetInMs / 60000) * -1;
};

/**
 *
 * @param {*} date - ex: '2022-11-29T22:00:00.000Z' ISO Date string
 * @param {*} timeZone - ex: 'America/Dawson_Creek', is optional, 'undefined' is handled in 'toLocaleString'
 * @returns - ex: {hour:'3:00', period:'pm' }
 */
export const convertFromDateToAmPmFormat = (date, timeZone) => {
  const time = new Date(date)
    .toLocaleString('en-US', { timeZone, hour: 'numeric', minute: 'numeric', hour12: true })
    .split(/\s/);

  return {
    hour: time[0],
    period: time[1].toLowerCase(),
  };
};

/**
 *
 * @param {*} amPmObject - ex: {hour:'3:00', period:'pm' }
 * @param {*} timeZone - ex: 'America/Dawson_Creek', is optional, 'undefined' is handled in 'getOffsetBetweenTimeZones', default is device timeZone
 * @returns - 2022-11-29T15:00:00-07:00
 */
export const convertFromAmPmFormatToDate = (amPmObject, timeZone) => {
  const offsetDiff = getOffsetBetweenTimeZones(timeZone, 'UTC');

  return moment(`${amPmObject.hour} ${amPmObject.period}`, ['h:mm A']).utcOffset(offsetDiff, true).format();
};

export const convertDayTimeToFormat = (day, format, timeZone) => {
  const copyDay = cloneDeep(day);

  copyDay.time = day.time.map((el) => {
    const copyEl = cloneDeep(el);

    if (format === 'amPm') {
      copyEl.from = convertFromDateToAmPmFormat(el.from, timeZone);
      copyEl.to = convertFromDateToAmPmFormat(el.to, timeZone);
    }

    if (format === 'date') {
      copyEl.from = convertFromAmPmFormatToDate(el.from, timeZone);
      copyEl.to = convertFromAmPmFormatToDate(el.to, timeZone);
      copyEl.baseDate = convertFromAmPmFormatToDate({ hour: '12:00', period: 'pm' }, timeZone); // needed to calculate the 'previous', 'current' or 'next' day in the 'timeSlots' logic
    }

    return copyEl;
  });

  return copyDay;
};

export const convertDaysTimeToFormat = (days, format, timeZone) => {
  return days.map((day) => convertDayTimeToFormat(day, format, timeZone?.timezone));
};

export const isCurrentTimeBigger = (day, timeAmPmFormat) => {
  const currentTime = new Date().getTime();
  const orderDate = new Date(day);

  const orderDay = orderDate.getDate();
  const orderMonth = orderDate.getMonth();
  const orderYear = orderDate.getFullYear();

  let orderStartTime = convertFromAmPmFormatToDate(timeAmPmFormat);

  orderStartTime = new Date(orderStartTime).setDate(orderDay);
  orderStartTime = new Date(orderStartTime).setMonth(orderMonth);
  orderStartTime = new Date(orderStartTime).setFullYear(orderYear);

  return currentTime > orderStartTime;
};

export const isDatePassed = (day, time) => {
  const isDayPassed = setSecondsToDefault(new Date()).getTime() > setSecondsToDefault(day).getTime();

  if (isDayPassed) {
    return true;
  }

  const isDayCurrent = moment().isSame(day, 'day');

  const currentDayTime = convertFromAmPmFormatToDate({
    hour: moment().format('HH:mm'),
    period: moment().format('A'),
  });

  const isTimeOfCurrentDayPassed = currentDayTime > convertFromAmPmFormatToDate(time);

  return isDayCurrent && isTimeOfCurrentDayPassed;
};

export const timeDiff = (t1, t2) => {
  const diff = t2.getTime() - t1.getTime();
  const diffHours = Math.floor(diff / (1000 * 60 * 60));
  const diffMinutes = Math.floor(diff / (1000 * 60));
  const diffSeconds = Math.floor(diff / 1000);

  if (diffHours >= 12) return 'some time ago';
  if (diffHours > 0) return `${diffHours}h ago`;
  if (diffMinutes > 0) return `${diffMinutes}m ago`;
  if (diffSeconds > 0) return `${diffSeconds}s ago`;
  return 'right now';
};

export const convertMsToMinutes = (ms) => {
  return Math.round(ms / 60000);
};

export const convertMsToHours = (ms) => {
  return Math.round(ms / 3600000);
};

const getNextDayStartTime = (from, meetingTimeMS, nextDayCorrection = false) => {
  const fromTimeMS = new Date(from).getTime();
  let oldStartTimeMS = new Date(convertFromAmPmFormatToDate({ hour: '12:00', period: 'am' })).getTime();
  if (nextDayCorrection) oldStartTimeMS += ALL_DAY.SECONDS * MS_IN_SECOND;

  let newStartTimeMS = fromTimeMS;

  while (newStartTimeMS < oldStartTimeMS) {
    newStartTimeMS += meetingTimeMS;
  }

  return new Date(newStartTimeMS).toISOString();
};

const startAndEndOnPrevDay = (convertedFrom, convertedTo, time, daysThatNeedChanges, days, index) => {
  const prevDayPeriod = [
    {
      ...time,
      from: convertedFrom._i,
      to: convertedTo._i,
    },
  ];

  if (index - 1 >= 0) {
    const times = daysThatNeedChanges[days[index - 1].nameOfDay]
      ? prevDayPeriod.concat(daysThatNeedChanges[days[index - 1].nameOfDay])
      : prevDayPeriod;
    daysThatNeedChanges[days[index - 1].nameOfDay] = times;
  } else {
    const times = daysThatNeedChanges[days[6].nameOfDay]
      ? prevDayPeriod.concat(daysThatNeedChanges[days[6].nameOfDay])
      : prevDayPeriod;
    daysThatNeedChanges[days[6].nameOfDay] = times;
  }

  return null;
};

const startOnPrevEndOnCurrent = (
  convertedFrom,
  convertedTo,
  time,
  daysThatNeedChanges,
  days,
  index,
  meetingPackage,
) => {
  const breakTime = meetingPackage.breakTime || defaultDuration?.breakTime;
  const sessionTime = meetingPackage.sessionTime || defaultDuration?.sessionTime;
  const meetingTimeMS = (breakTime + sessionTime) * SECOND_IN_MINUTES * MS_IN_SECOND;
  const nextDayStartTime = getNextDayStartTime(convertedFrom._i, meetingTimeMS);

  const prevDayPeriod = [
    {
      ...time,
      from: convertedFrom._i,
      to: nextDayStartTime,
    },
  ];

  if (index - 1 >= 0) {
    const times = daysThatNeedChanges[days[index - 1].nameOfDay]
      ? prevDayPeriod.concat(daysThatNeedChanges[days[index - 1].nameOfDay])
      : prevDayPeriod;
    daysThatNeedChanges[days[index - 1].nameOfDay] = times;
  } else {
    const times = daysThatNeedChanges[days[6].nameOfDay]
      ? prevDayPeriod.concat(daysThatNeedChanges[days[6].nameOfDay])
      : prevDayPeriod;
    daysThatNeedChanges[days[6].nameOfDay] = times;
  }

  const from = nextDayStartTime;
  const to = convertedTo._i;
  const nextDayTimePeriodMS = new Date(to).getTime() - new Date(from).getTime();

  return meetingTimeMS <= nextDayTimePeriodMS
    ? {
        ...time,
        from,
        to,
      }
    : null;
};

const startAndEndOnNextDay = (convertedFrom, convertedTo, time, daysThatNeedChanges, days, index) => {
  const nextDayPeriod = [
    {
      ...time,
      from: convertedFrom._i,
      to: convertedTo._i,
    },
  ];

  if (index + 1 < days.length) {
    const times = daysThatNeedChanges[days[index + 1].nameOfDay]
      ? nextDayPeriod.concat(daysThatNeedChanges[days[index + 1].nameOfDay])
      : nextDayPeriod;
    daysThatNeedChanges[days[index + 1].nameOfDay] = times;
  } else {
    const times = daysThatNeedChanges[days[0].nameOfDay]
      ? nextDayPeriod.concat(daysThatNeedChanges[days[0].nameOfDay])
      : nextDayPeriod;
    daysThatNeedChanges[days[0].nameOfDay] = times;
  }

  return null;
};

const startOnCurrentEndOnNext = (
  convertedFrom,
  convertedTo,
  time,
  daysThatNeedChanges,
  days,
  index,
  meetingPackage,
) => {
  const breakTime = meetingPackage.breakTime || defaultDuration.breakTime;
  const sessionTime = meetingPackage.sessionTime || defaultDuration.sessionTime;
  const meetingTimeMS = (breakTime + sessionTime) * SECOND_IN_MINUTES * MS_IN_SECOND;
  const nextDayStartTime = getNextDayStartTime(convertedFrom._i, meetingTimeMS, true);
  const from = nextDayStartTime;
  const to = convertedTo._i;
  const nextDayTimePeriodMS = new Date(to).getTime() - new Date(from).getTime();
  if (meetingTimeMS <= nextDayTimePeriodMS) {
    const nextDayPeriod = [
      {
        ...time,
        from,
        to,
      },
    ];

    if (index + 1 < days.length) {
      const times = daysThatNeedChanges[days[index + 1].nameOfDay]
        ? nextDayPeriod.concat(daysThatNeedChanges[days[index + 1].nameOfDay])
        : nextDayPeriod;
      daysThatNeedChanges[days[index + 1].nameOfDay] = times;
    } else {
      const times = daysThatNeedChanges[days[0].nameOfDay]
        ? nextDayPeriod.concat(daysThatNeedChanges[days[0].nameOfDay])
        : nextDayPeriod;
      daysThatNeedChanges[days[0].nameOfDay] = times;
    }
  }

  return {
    ...time,
    from: convertedFrom._i,
    to: nextDayStartTime,
  };
};

const isOnYearIntersection = (a, b) => a === 1 && b >= 365;

const checkYearIntersection = (fromDay, toDay, baseDay) => {
  let checkedFromDay = fromDay;
  let checkedToDay = toDay;
  if (isOnYearIntersection(baseDay, fromDay)) checkedFromDay = 0;
  if (isOnYearIntersection(baseDay, toDay)) checkedToDay = 0;
  if (isOnYearIntersection(fromDay, baseDay)) checkedFromDay = 367;
  if (isOnYearIntersection(toDay, baseDay)) checkedToDay = 367;
  return { checkedFromDay, checkedToDay };
};

const getBaseDayCorrection = (offsetDiff) => {
  let baseDayCorrection = 0;
  if (offsetDiff >= ALL_DAY.MINUTES / 2) baseDayCorrection = -1;
  if (offsetDiff <= -ALL_DAY.MINUTES / 2) baseDayCorrection = 1;
  return baseDayCorrection;
};
export const parseParamsDateAndTime = (paramsDateAndTime) => {
  const [dateTimePart, membersIdString] = paramsDateAndTime.split(',');
  const [datePart, timePart] = dateTimePart.split('T');
  const [time, periodUpperCase] = timePart.split('-');
  const membersId = membersIdString.split('|'); // Assuming possibility of multiple member IDs

  // Convert period to lowercase to match the expected input
  const period = periodUpperCase.toLowerCase();

  // Combine date and time parts and convert to the expected date format
  // Assuming the date and time are in the user's local timezone and need to be converted
  const dateTimeFormat = `${datePart} ${time} ${period.toUpperCase()}`;
  const timeSlotDay = new Date(dateTimeFormat);

  return { date: timeSlotDay, time, period, membersId };
};

export const convertAvailableDaysToTimezone = (days, meetingPackage, userTimezone) => {
  const daysThatNeedChanges = {};

  const convertedDays = days.map((day, index) => {
    if (day.isAvailableDay === false) {
      return {
        ...day,
        time: [],
      };
    }

    const convertedTime = day.time.map((time) => {
      const offsetDiff = getOffsetBetweenTimeZones(userTimezone, time.timeZone);
      const customerOffset = getOffsetBetweenTimeZones(userTimezone, 'UTC');
      const convertedFrom = moment(time.from).utcOffset(0).add(customerOffset, 'm');
      const convertedTo = moment(time.to).utcOffset(0).add(customerOffset, 'm');

      /**
       * moment().format('DDD') returns Number 'Day of Year'	ex: 1 2 ... 364 365
       * 'baseDate' & 'time.timeZone' needs to convert 'Member available timeSpan' to 'Customer timeZone' and mutate available days array to display
       */
      const fromDay = Number(convertedFrom.format('DDD'));
      const toDay = Number(convertedTo.format('DDD'));
      const baseDay = Number(moment(time.baseDate).format('DDD')) + getBaseDayCorrection(offsetDiff);
      const { checkedFromDay, checkedToDay } = checkYearIntersection(fromDay, toDay, baseDay);

      const isStartAndEndOnPrevDay = checkedFromDay < baseDay && checkedToDay < baseDay;
      const isStartOnPrevEndOnCurrent = checkedFromDay < baseDay && checkedToDay === baseDay;
      const isStartAndEndOnNextDay = checkedFromDay > baseDay && checkedToDay > baseDay;
      const isStartOnCurrentEndOnNext = checkedFromDay === baseDay && checkedToDay > baseDay;
      delete time.baseDate;
      delete time.timeZone;

      if (isStartAndEndOnPrevDay) {
        return startAndEndOnPrevDay(convertedFrom, convertedTo, time, daysThatNeedChanges, days, index);
      }
      if (isStartOnPrevEndOnCurrent) {
        return startOnPrevEndOnCurrent(
          convertedFrom,
          convertedTo,
          time,
          daysThatNeedChanges,
          days,
          index,
          meetingPackage,
        );
      }
      if (isStartAndEndOnNextDay) {
        return startAndEndOnNextDay(convertedFrom, convertedTo, time, daysThatNeedChanges, days, index);
      }
      if (isStartOnCurrentEndOnNext) {
        return startOnCurrentEndOnNext(
          convertedFrom,
          convertedTo,
          time,
          daysThatNeedChanges,
          days,
          index,
          meetingPackage,
        );
      }

      /** Start and End on Current Day */
      return {
        ...time,
        from: convertedFrom._i,
        to: convertedTo._i,
      };
    });

    return {
      ...day,
      time: convertedTime,
    };
  });

  const updatedDays = convertedDays.map((day) => {
    if (daysThatNeedChanges[day.nameOfDay]) {
      if (!day.isAvailableDay) {
        day.time.shift();
      }
      for (const time of daysThatNeedChanges[day.nameOfDay]) {
        day.time.unshift(time);
      }

      day.isAvailableDay = true;
    }

    const filteredTimes = [];
    for (const time of day.time) {
      if (time) filteredTimes.push(time);
    }

    day.time = filteredTimes;
    if (!filteredTimes.length) day.isAvailableDay = false;

    return day;
  });

  return updatedDays;
};

export const convertTimezonesForOrderTime = (timezoneFrom, timezoneTo, startTime, startPeriod, selectedDay, offset) => {
  const userOrderTime = moment(convertFromAmPmFormatToDate({ hour: startTime, period: startPeriod })).utcOffset(
    typeof offset === 'number' ? offset * -1 : offset,
    true,
  );

  const meetingCreatorSlotTime = userOrderTime;

  const getFormattedMeetingCreatorSlotTime = meetingCreatorSlotTime.format('LT').split(' ');
  startTime = getFormattedMeetingCreatorSlotTime[0];
  startPeriod = getFormattedMeetingCreatorSlotTime[1].toLowerCase();

  const isNextDay =
    userOrderTime.date() > meetingCreatorSlotTime.date() ||
    userOrderTime.month() > meetingCreatorSlotTime.month() ||
    userOrderTime.year() > meetingCreatorSlotTime.year();
  const isPrevDay =
    userOrderTime.date() < meetingCreatorSlotTime.date() ||
    userOrderTime.month() < meetingCreatorSlotTime.month() ||
    userOrderTime.year() < meetingCreatorSlotTime.year();

  if (isNextDay) {
    selectedDay.setDate(selectedDay.getDate() - 1);
  } else if (isPrevDay) {
    selectedDay.setDate(selectedDay.getDate() + 1);
  }

  return {
    startTime,
    startPeriod,
    selectedDay,
  };
};

export const getDeviceTimeZone = () => {
  const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
  return {
    offset: new Date().getTimezoneOffset(),
    time: getTimeFromTimezone(new Date(), timezoneName),
    timezone: timezoneName,
    name:
      Intl.DateTimeFormat()
        .resolvedOptions()
        .timeZone.substring(Intl.DateTimeFormat().resolvedOptions().timeZone.lastIndexOf('/') + 1) + ' Time',
  };
};

export const getDeviceTimeZoneName = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getDeviceTimeZoneAbbr = () => {
  return new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
};

export const countUsedDays = (dateFrom) => {
  const oneDay = convertToHours(24);
  const currentDate = new Date();
  const startDate = new Date(dateFrom);
  return Math.round(Math.abs((currentDate - startDate) / oneDay));
};
