import { useFormik } from 'formik';
import { showErrorMessage } from 'helpers/errors';
import { useDebounce } from 'hooks/useDebounce';
import image4 from 'images/image4.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import NavBrand from 'sharedComponents/navbars/partials/NavBrand';
import { checkUserName, editAccountInformation } from 'store/reducers/userReducer';
import { userDataSelector } from 'store/selectors';
import isLength from 'validator/es/lib/isLength';

const Changeusername = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userSelector = useSelector(userDataSelector);
  const { user, isUserNameValid } = userSelector;

  const validate = (values) => {
    const errors = {};
    if (!values.userName) {
      errors.userName = t('Required');
    } else if (!isLength(values.userName, { min: 3, max: 30 })) {
      errors.userName = t('Must be a more than 3 and less than 30');
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      userName: user?.username,
    },
    onSubmit: ({ userName }) => {
      if (user.username !== userName) {
        dispatch(
          editAccountInformation({
            username: userName,
          }),
        );
      }
      navigate('/confirmusername');
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validate,
  });

  const checkUserNameAccessibility = (userName) => {
    dispatch(checkUserName({ userName }));
  };
  const checkUserNameWithDebounce = useDebounce(checkUserNameAccessibility, 500);

  const [countChangeFormikValue, setCountChangeFormikValue] = useState(0);

  useEffect(() => {
    const userName = formik.values.userName?.trim();

    if (userName && countChangeFormikValue > 1 && userName !== user.username) {
      checkUserNameWithDebounce(userName);
    }

    if (countChangeFormikValue < 2) {
      setCountChangeFormikValue(countChangeFormikValue + 1);
    }
  }, [formik.values.userName]);

  const handlerBackClick = () => {
    navigate('/confirmusername');
  };

  if (!user?.username) {
    return <div />;
  }

  return (
    <>
      <nav
        id="navbar-main"
        className="navbar-horizontal navbar-main navbar navbar-expand-lg justify-content-left row p-0 zindex-1 position-absolute w-100 mx-0"
      >
        <Col className="col-lg-12 col-sm-12 d-flex justify-content-center bg-white col p-2 shadow ">
          <NavBrand isTransparent={false} />
        </Col>
      </nav>
      <div className="container-fluid">
        <Row className="min-vh-100-50">
          <Col className="order-lg-2 col-lg-6 col-sm-12 d-sm-none flex-grow-1 d-lg-flex align-items-center justify-content-center">
            <div className="onboarding-image mw-600">
              <img src={image4} alt={'img'} />
            </div>
          </Col>

          <Col className="order-lg-1 col-lg-6 col-sm-12 flex-grow-1 d-flex align-items-center justify-content-center bg-white">
            <Form role="form" className="mw-500-sm" onSubmit={formik.handleSubmit}>
              <Row>
                <Col className="col-sm-12 d-lg-block">
                  <h1 className="h1 text-center">Change Username {user.username}</h1>
                  <p className="text-center">Pick a username for your account. You can always change it later</p>
                  <FormGroup className="has-feedback">
                    <Input
                      id="input-brand-name"
                      className="form-control"
                      placeholder="Type another username"
                      type="text"
                      autoComplete="email"
                      size="lg"
                      name="userName"
                      value={formik.values.userName}
                      onChange={formik.handleChange}
                    />
                    {countChangeFormikValue > 1 &&
                      (isUserNameValid === true || formik.values.userName === user.username ? (
                        <i className="checkmark-full bg-success form-control-feedback" />
                      ) : (
                        <i className="checkmark-full bg-warning x-sign form-control-feedback" />
                      ))}
                    {formik.errors?.userName && showErrorMessage(formik.errors.userName)}
                    {userSelector?.checkUserNameError && showErrorMessage(userSelector.checkUserNameError?.message)}
                  </FormGroup>
                </Col>
              </Row>
              <div className="">
                <Button
                  type="submit"
                  color="primary"
                  size="xl"
                  block
                  disabled={!isUserNameValid || formik.values.userName === '' || userSelector?.isCheckUserNameLoading}
                >
                  Continue
                </Button>
                <Button
                  type="button"
                  size="xl"
                  block
                  onClick={handlerBackClick}
                  className="btn-link bg-transparent border-0 text-muted"
                >
                  Back
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Changeusername;
