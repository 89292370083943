// activityReducer.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from 'services/api'; // Adjust this path as per your project structure.

// Async thunks for activity operations
export const fetchActivitiesByCustomerId = createAsyncThunk(
  'activities/byCustomer',
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await api.fetchActivitiesByCustomerId(customerId);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  activities: [],
  isLoading: false,
  error: null
};

const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    resetActivityError: (state) => {
      state.error = null;
    },
    resetActivities: (state) => {
      state.activities = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivitiesByCustomerId.fulfilled, (state, action) => {
        state.activities = action.payload || []; // Ensure activities array is emptied if payload is undefined
        state.isLoading = false;
      })
      .addCase(fetchActivitiesByCustomerId.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchActivitiesByCustomerId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const { resetActivityError, resetActivities } = activitiesSlice.actions;

export default activitiesSlice.reducer;
