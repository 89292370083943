import { useFormik } from 'formik';
import { showErrorMessage } from 'helpers/errors';
import { isTeamMember } from 'helpers/roleHelper';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, InputGroup, Row } from 'reactstrap';
import Loader from 'sharedComponents/Loader';
import NavBrand from 'sharedComponents/navbars/partials/NavBrand';
import { editAccountInformation, getUser } from 'store/reducers/userReducer';
import { userDataSelector } from 'store/selectors';
import isLength from 'validator/es/lib/isLength';

const Addname = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userSelector = useSelector(userDataSelector);

  useEffect(() => {
    dispatch(getUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // useEffect(() => {
  //   window.fpr('referral', { email: userSelector.user.primaryEmail });
  // }, [userSelector.user.primaryEmail]);


  useEffect(() => {
    if (userSelector.isSuccessEditAccountInfo) {
      navigate('/confirmusername');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelector.isSuccessEditAccountInfo]);

  const validate = (values) => {
    const errors = {};
    if (!values.fullName) {
      errors.fullName = t('Required');
    } else if (!isLength(values.fullName, { min: 3, max: 30 })) {
      errors.fullName = t('Must be a more than 3 and less than 30');
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      fullName: '',
    },
    onSubmit: async ({ fullName }) => {
      dispatch(
        editAccountInformation({
          fullName,
          username: fullName,
        }),
      );
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate,
  });

  return (
    <>
      {userSelector.isEditAccountInfoLoading && <Loader show={true} />}
      {!userSelector.isEditAccountInfoLoading && (
        <>
          <nav
            id="navbar-main"
            className="navbar-horizontal navbar-main navbar navbar-expand-lg justify-content-left row p-0 zindex-1 position-absolute w-100 mx-0"
          >
            <Col className="col-lg-12 col-sm-12 d-flex justify-content-center bg-white col p-2 shadow ">
              <NavBrand isTransparent={false} />
            </Col>
          </nav>
          <div className="container-fluid">
            <Row className="min-vh-100-50">
              <Col className="order-lg-2 col-lg-6 col-sm-12 d-sm-none flex-grow-1 d-lg-flex align-items-center justify-content-center">
                <div className="onboarding-image mw-600-sm">
                  <img src={'images/image2.svg'} alt={'img'} />
                </div>
              </Col>

              <Col className="order-lg-1 col-lg-6 col-sm-12 flex-grow-1 d-flex align-items-center justify-content-center bg-white">
                <Form role="form" className="mw-500-sm" onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col className="col-sm-12 d-lg-block">
                      {userSelector.user && isTeamMember(userSelector.user.roles[0].name) ? (
                        <h1 className="h1 text-center">Add Your Name</h1>
                      ) : (
                        <h1 className="h1 text-center">Add Your Company Name</h1>
                      )}
                      <p className="text-center">Add your company name so customers can find you</p>
                      <FormGroup>
                        <InputGroup>
                          <Input
                            id="input-brand-name"
                            className="form-control"
                            placeholder="Type your name"
                            type="text"
                            autoComplete="fullName"
                            name="fullName"
                            size="lg"
                            value={formik.values.fullName}
                            onChange={formik.handleChange}
                          />
                        </InputGroup>
                        {formik.errors?.fullName && showErrorMessage(formik.errors.fullName)}
                        {userSelector?.editAccountInformationError &&
                          showErrorMessage(userSelector?.editAccountInformationError.message)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="">
                    {formik.errors?.other && showErrorMessage(formik.errors.other)}
                    <Button type="submit" color="primary" size="xl" block>
                      Continue
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default Addname;
