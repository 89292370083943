import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userDataSelector } from 'store/selectors';

function usePaidStatus() {
  const [status, setStatus] = useState(null);
  const userSelector = useSelector(userDataSelector);

  useEffect(() => {
    if (userSelector.user) {
      const draftedInvoice = userSelector.user.invoiceData.invoiceStatus === 'draft';
      const isPaidInvoice =
        userSelector.user.invoiceData.invoiceStatus === 'paid' && userSelector.user.invoiceData.isInvoicePaid === true;

      // we need second condition, because between invoice created and finalized - one hour of time
      // we cant pay drafted invoice, we can pay only after its finalized invoice
      const isPaid = isPaidInvoice || draftedInvoice;

      isPaid ? setStatus(true) : setStatus(false);
    }
  }, [userSelector.isGetUserLoading, userSelector.isUserLoginSuccessful, userSelector.user, status]);

  return status;
}

export default usePaidStatus;
