import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import * as api from 'services/api';
import { asyncActionsCreator } from 'store/reducersHelper';

export const loadMeetings = createAsyncThunk('meetings/loadMeetings', async () => await api.loadMeetings());

export const loadMeetingsByUserId = createAsyncThunk(
  'meetings/loadMeetingsByUserId',
  async (userId) => await api.loadAllAttachedMeetingsByUserId(userId),
);

export const deleteMeeting = createAsyncThunk(
  'meetings/deleteMeetingById',
  async (meetingId) => await api.deleteMeetingById(meetingId),
);

export const duplicateMeeting = createAsyncThunk(
  'meetings/duplicateMeeting',
  async (meetingData) => await api.saveMeeting(meetingData),
);

export const updateMeetings = createAsyncThunk('meetings/updateMeetings', async (meetingDataForUpdate) =>
  api.updateMeeting(meetingDataForUpdate),
);

export const updateMeetingTeamMemberData = createAsyncThunk(
  'currentMeeting/updateMeetingTeamMemberData',
  async (meetingDataForUpdate) => api.updateMeetingTeamMemberData(meetingDataForUpdate),
);

export const toggleAssignAll = createAsyncThunk(
  'meetings/toggleAssignAll',
  async (updateData) => await api.toggleAssignAll(updateData),
);

export const updateTeamMemberAssignOnDisable = createAsyncThunk(
  'meetings/updateTeamMemberAssignOnDisable',
  async (data) => api.updateTeamMemberAssignOnDisable(data),
);

const initialState = {
  meetings: [],
};
export const meetingsSlice = createSlice({
  name: 'meetings',
  initialState,
  reducers: {
    addMeetingToState: (state, { payload }) => {
      state.meetings.push(payload);
    },
    updateMeetingInState: (state, { payload }) => {
      if (state.meetings) {
        state.meetings = state.meetings.map((item) => {
          return item._id === payload._id ? payload : item;
        });
      }
    },
    resetMeetings: () => initialState,
  },

  extraReducers: {
    ...asyncActionsCreator(
      loadMeetings,
      'loadMeetings',
      {
        fulfilled: (state, { payload }) => {
          state.isLoadMeetingsLoading = false;
          state.meetings = payload.myMeetings;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(deleteMeeting, 'deleteMeeting', {
      fulfilled: (state, { payload }) => {
        const index = state.meetings.findIndex((item) => item._id === payload.meeting._id);
        state.meetings[index] = payload.meeting;
      },
    }),

    ...asyncActionsCreator(duplicateMeeting, 'duplicateMeeting', {
      fulfilled: (state, { payload }) => {
        state.meetings.push(payload.result);
      },
    }),

    ...asyncActionsCreator(toggleAssignAll, 'toggleAssignAll', {
      fulfilled: (state, { payload }) => {
        state.meetings = state.meetings.map((item) => {
          if (item._id === payload._id) {
            return payload;
          }

          return item;
        });
      },
    }),

    ...asyncActionsCreator(updateMeetings, 'updateMeetings', {
      fulfilled: (state, { payload }) => {
        if (!isEmpty(state.meetings)) {
          state.meetings = state.meetings.map((item) => {
            if (item._id === payload.meeting._id) {
              return payload.meeting;
            }
            return item;
          });
        }
      },
    }),

    ...asyncActionsCreator(updateMeetingTeamMemberData, 'updateMeetingTeamMemberData', {
      fulfilled: (state, { payload }) => {
        state.meetings = state.meetings?.map((item) => (item._id === payload.meeting._id ? payload.meeting : item));
      },
    }),
  },
});

export const { addMeetingToState, updateMeetingInState, resetMeetings } = meetingsSlice.actions;

export default meetingsSlice.reducer;
