import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userDataSelector } from 'store/selectors';

function useAuth() {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const userSelector = useSelector(userDataSelector);

  useEffect(() => {
    if (userSelector.user || userSelector.isUserLoginSuccessful) {
      setIsLoggedIn(true);
    } else if (!localStorage.getItem('token')) {
      setIsLoggedIn(false);
    } else if (userSelector.hasOwnProperty('isGetUserLoading') && !userSelector.isGetUserLoading) {
      userSelector.getUserError ? setIsLoggedIn(false) : setIsLoggedIn(true);
    }
  }, [userSelector.isGetUserLoading, userSelector.isUserLoginSuccessful, userSelector.user]);

  return isLoggedIn;
}

export default useAuth;
