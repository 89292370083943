// customerReducer.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from 'services/api'; // Ensure this module has methods to interact with backend endpoints.

export const getCustomersByOwnerId = createAsyncThunk(
  'customers/getCustomersByOwnerId',
  async (ownerId, { rejectWithValue }) => {
    try {
      const response = await api.getCustomersByOwnerId(ownerId);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const createCustomer = createAsyncThunk(
  'customers/createCustomer',
  async (customerData, { rejectWithValue }) => {
    try {
      const response = await api.createCustomer(customerData);
      return response;
    } catch (err) {
      console.error("Error creating customers:", err);
      return rejectWithValue(err.response ? err.response : { message: 'No response received' });
    }
  }
);

export const importCustomers = createAsyncThunk(
  'customers/importCustomers',
  async (customersData, { rejectWithValue }) => {
    try {
      const response = await api.importCustomers(customersData);
      return response;
    } catch (err) {
      console.error("Error creating customer:", err);
      return rejectWithValue(err.response ? err.response : { message: 'No response received' });
    }
  }
);

export const findCustomer = createAsyncThunk(
  'customers/findCustomer',
  async (criteria, { rejectWithValue }) => {
    try {
      const response = await api.findCustomer(criteria);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  'customers/updateCustomer',
  async ({ customerId, updateData }, { rejectWithValue }) => {
    try {
      const response = await api.updateCustomer({ customerId, updateData });
      if (response) {
        return updateData;  // Ensure this is what you expect
      }
    } catch (err) {
      console.error("Error in updateCustomer:", err);
      // More defensive check: ensure err.response and err.response.data exist
      return rejectWithValue(err.response ? err.response.data : { error: 'Network or server error' });
    }
  }
);

export const updateStatusStructureByOwnerId = createAsyncThunk(
  'customers/updateStatusStructureByOwnerId',
  async ({ ownerId, statuses }, { rejectWithValue }) => {
    try {
      const response = await api.updateStatusStructureByOwnerId({ ownerId, statuses });
      return response.data;
    } catch (err) {
      console.error("Error in API call:", err);
      return rejectWithValue(err.response ? err.response.data : { error: 'Network or server error' });
    }
  }
);

export const updatePrioritiesStructureByOwnerId = createAsyncThunk(
  'customers/updatePrioritiesStructureByOwnerId',
  async ({ ownerId, priorities }, { rejectWithValue }) => {
    try {
      const response = await api.updatePrioritiesStructureByOwnerId({ ownerId, priorities });
      return response.data;
    } catch (err) {
      console.error("Error in API call:", err);
      return rejectWithValue(err.response ? err.response.data : { error: 'Network or server error' });
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  'customers/deleteCustomer',
  async ({customerId, currentCustomers}, { rejectWithValue }) => {
    try {
      const response = await api.deleteCustomer({ customerId });
      if (!response) {
        throw new Error("No response from server");
      }
      // Return the updated customers array
      const updatedCustomers = currentCustomers.filter(customer => customer._id !== customerId);
      return updatedCustomers;
    } catch (err) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

// Initial state
const initialState = {
  customers: { customers: [] },
  isLoading: false,
  error: null,
};

// Utility function to ensure customers array
const ensureCustomersArray = (state) => {
  if (!Array.isArray(state.customers)) {
    console.warn('state.customers is not an array, resetting to empty array');
    return { ...state, customers: { customers: [] } };
  }
  return state;
};

// Customer slice
export const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomersByOwnerId.fulfilled, (state, action) => {
        state.customers = action.payload;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state = ensureCustomersArray(state);
        state.customers.customers.push(action.payload.customer);
      })
      .addCase(importCustomers.fulfilled, (state, action) => {
        state = ensureCustomersArray(state);        
        if (action.payload?.customers?.length > 0) {
          state.customers.customers.push(...action.payload.customers);
        }
      })
      .addCase(findCustomer.fulfilled, (state, action) => {
        state.customers.customers = action.payload;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        const index = state.customers.customers.findIndex(customer => customer._id === action.meta.arg.customerId);
        if (index !== -1) {
          state.customers.customers[index] = { ...state.customers.customers[index], ...action.payload };
        }
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.customers.customers = action.payload;
      })
      .addMatcher(
        (action) => action.type.startsWith('customers/') && action.type.endsWith('/pending'),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type.startsWith('customers/') && action.type.endsWith('/fulfilled'),
        (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type.startsWith('customers/') && action.type.endsWith('/rejected'),
        (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
        }
      );
  },
});

export const { resetError } = customerSlice.actions;

export default customerSlice.reducer;