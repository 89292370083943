import { createAsyncThunk } from '@reduxjs/toolkit';
import { customSolutionId } from 'constants/meeting.const';
import * as api from 'services/api';
import {
  setCurrentOrder,
  setIsTimeAvailable,
  setIsUpdateOrderSuccess,
  updateOrderById,
  updateOrderLocation,
} from '../ordersReducer';

export const editOrder = createAsyncThunk('orders/editOrder', async (data, { dispatch, getState }) => {
  const { checkTimeOrderData, orderForUpdate, orderId, locationId, newOrderDate } = data;
  try {
    const payload = await api.checkAvailabilityOfTime({
      ...checkTimeOrderData,
      ...(checkTimeOrderData.meetingCreatorId._id && { meetingCreatorId: checkTimeOrderData.meetingCreatorId._id }),
    });
    if (!payload.isTimeAvailable) return dispatch(setIsTimeAvailable(false));
    dispatch(setIsTimeAvailable(true));
    const locationLink = orderForUpdate.location;

    const dataForOrderUpdate = {
      orderId,
      updatedFields: {
        orderDate: newOrderDate,
        orderTitle: orderForUpdate.title,
        orderNote: orderForUpdate.description,
        participantLink: locationLink,
        hostLink: locationLink,
        location: locationId,
      },
    };
    await dispatch(updateOrderById(dataForOrderUpdate));
    const { isUpdateOrderSuccess } = getState().ordersData;
    if (isUpdateOrderSuccess) {
      if (locationId !== customSolutionId) return payload;

      dispatch(updateOrderLocation({ newOrderDate, orderId, newLocation: orderForUpdate.location }));
      dispatch(setIsUpdateOrderSuccess(false));
      dispatch(setCurrentOrder(null));
    }
  } catch (err) {
    return err;
  }
});

export const removeCustomer = createAsyncThunk('orders/removeCustomer', async (orderData, { dispatch }) => {
  try {
    const payload = await api.removeCustomer(orderData);
    payload.updatedOrder && dispatch(setCurrentOrder(payload.updatedOrder));
    return payload;
  } catch (err) {
    return err;
  }
});
