import { configureStore } from '@reduxjs/toolkit';
import activityReducer from './reducers/activityReducer';
import authReducer from './reducers/authReducer';
import currentMeetingReducer from './reducers/currentMeetingReducer';
import customerReducer from './reducers/customerReducer';
import meetingsReducer from './reducers/meetingsReducer';
import noteReducer from './reducers/noteReducer';
import ordersReducer from './reducers/ordersReducer';
import taskReducer from './reducers/taskReducer';
import userReducer from './reducers/userReducer';

export default configureStore({
  reducer: {
    authData: authReducer,
    currentMeetingData: currentMeetingReducer,
    meetingsData: meetingsReducer,
    userData: userReducer,
    ordersData: ordersReducer,
    customersData: customerReducer,
    notesData: noteReducer,
    activitiesData: activityReducer,
    tasksData: taskReducer,
  },
});
