import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from 'services/api';
import { asyncActionsCreator } from 'store/reducersHelper';
import { setUser } from './userReducer';

export const sendVerificationCodeToEmail = createAsyncThunk(
  'auth/sendVerificationCodeToEmail',
  async (data) => await api.sendVerificationCode(data),
);

export const verifyEmail = createAsyncThunk('auth/verifyEmail', async (verificationCode, { dispatch }) => {
  const res = await api.verifyEmail(verificationCode);
  if (res.isEmailVerified) {
    localStorage.setItem('token', res.accessToken);
    localStorage.setItem('refreshToken', res.refreshToken);
    dispatch(setUser(res.user));
  }
  return res;
});

export const checkRegistrationToken = createAsyncThunk(
  'auth/checkRegistrationToken',
  async (registrationToken) => await api.checkRegistrationToken(registrationToken),
);

const initialState = {
  isEmailVerified: null,
  isAcceptRegistration: null,
  googleAuthError: null,
  googleConnectInProgress: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: () => initialState,
    setAcceptRegistration: (state, { payload }) => {
      state.isAcceptRegistration = payload;
    },
    setAuthError: (state, { payload }) => {
      state[payload.name] = payload.value;
    },
    setIsGoogleConnectInProgress: (state, { payload }) => {
      state.googleConnectInProgress = payload;
    },
  },
  extraReducers: {
    ...asyncActionsCreator(sendVerificationCodeToEmail, 'sendVerificationCodeToEmail'),

    ...asyncActionsCreator(verifyEmail, 'verifyEmail', {
      fulfilled: (state, { payload }) => {
        state.isEmailVerified = payload.isEmailVerified;
      },
    }),

    ...asyncActionsCreator(checkRegistrationToken, 'checkRegistrationToken', {
      pending: (state) => {
        state.isAcceptRegistration = null;
        state.checkRegistrationTokenError = null;
      },
      fulfilled: (state) => {
        state.isAcceptRegistration = true;
      },
      rejected: (state, { error }) => {
        state.isAcceptRegistration = false;
        state.checkRegistrationTokenError = error;
      },
    }),
  },
});

export const { setAcceptRegistration, setAuthError, setIsGoogleConnectInProgress, resetAuth } = authSlice.actions;

export default authSlice.reducer;
