import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userDataSelector } from 'store/selectors';

const statusArray = ['active', 'trialing', 'past_due', 'incomplete'];

function useSubStatus() {
  const [status, setStatus] = useState(null);
  const userSelector = useSelector(userDataSelector);

  useEffect(() => {
    if (userSelector.user) {
      const isActive = statusArray.includes(userSelector.user.plan.subscriptionStatus);

      isActive ? setStatus(true) : setStatus(false);
    }
  }, [userSelector.isGetUserLoading, userSelector.isUserLoginSuccessful, userSelector.user, status]);

  return status;
}

export default useSubStatus;
