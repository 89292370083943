export const google = {
  REGISTRATION: 'GOOGLE_REGISTRATION',
  LOGIN: 'GOOGLE_LOGIN',
  CONNECT: 'GOOGLE_CONNECT',
  CALENDAR_CONNECT: 'GOOGLE_CALENDAR_CONNECT',
  AUTH_ERROR: 'googleAuthError',
  CONNECT_ERROR: 'googleConnectError',
  CALENDAR_ERROR: 'googleCalendarError',
  GOOGLE_EVENT_ID: '1',
}; // should be the same as the constants 'GoogleAuth' & 'GoogleError' on the backend: src/interfaces/auth.interfaces.ts
