const CrownSvg = ({color, width, height}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 155 126"
      style={{ width: width || '25px', height: height || '27.5px', verticalAlign: 'top' }}
      className="mr-2"
    >
      <path
        fill={color ? color : "#ff9009"}
        d="M112.3 57.5c-.4.2-1.7-1.4-3-3.6L92.4 25 77.4 0 63.2 24A6272.2 6272.2 0 0 0 43 57.8c-.2 0-10-6.7-21.8-15C1.6 29.4-.2 28.2 0 29.8L7.2 70l7 38.8h62.3l63.2.3c.8 0 2-6.2 8.1-39.8l7.2-40.6c0-.5-9.4 5.8-21 13.8l-21.7 15Z"
      />
      <path
        fill={color ? color :"#EBAB18"}
        d="M35 110h43.2l62.6-.2 7-39.2 7.2-41c.1-1-.5-1-5 2.1l-4.4 3-11.9 8.3A821.4 821.4 0 0 1 112 58l-.3-.2L35 110Z"
      />
      <path
        fill={color ? color : "#FFCE2B"}
        d="M120 110H76.8l-62.6-.2-7-39.2-7.2-41c-.1-1 .5-1 5 2.1l4.4 3L21.3 43A824.5 824.5 0 0 0 43 58l.3-.2L120 110ZM14 114h127v12H14v-12Z"
      />
    </svg>
  );
};

export default CrownSvg;
