import useLogout from 'hooks/useLogout';
import React from 'react';
import { Button, Navbar } from 'reactstrap';
import NavBrandPowered from 'sharedComponents/navbars/partials/NavBrandPowered';

const AddNavbar = () => {
  const logout = useLogout();

  return (
    <>
      <Navbar className="nav justify-content-end row zindex-1 shadow-bottom px-2 m-0" expand="lg">
        <div className="position-absolute w-100 d-flex justify-content-center">
          <div className="nav-item">
            <NavBrandPowered />
          </div>
        </div>
        <Button onClick={logout} color="outline-secondary" className="bg-secondary py-0 position-absolute">
          Logout
        </Button>
      </Navbar>
    </>
  );
};

export default AddNavbar;
