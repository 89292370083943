import { get, httpDelete, patch, post } from './axios';

/* auth requests */
export const refreshToken = (refreshToken) => post('/auth/refresh', { refreshToken });
export const getGoogleAuthUrl = (body) => post('/auth/getGoogleAuthUrl', body);
export const getGoogleConnectUrl = (body) => post('/auth/getGoogleConnectUrl', body);
export const getGoogleCalendarConnectUrl = (body) => post('/auth/getGoogleCalendarConnectUrl', body);
export const otpAuthentication = (body) => post('/auth/email/otp-auth', body);
export const otpLoginByEmail = (body) => post('/auth/email/otp-login', body);
export const resetAuthOnLogout = () => get('/auth/email/reset-auth');
export const checkEmail = (body) => post('/auth/email/checkEmail', body);
export const checkUserName = (body) => post('/auth/email/checkUserName', body);
export const sendInviteToRegistration = (body) => post('/auth/sendInviteToRegistration', body);
export const checkRegistrationToken = (body) => post('/auth/checkRegistrationToken', body);

/* user requests */
export const sendVerificationCode = (body) => post('/users/sendCodeToConfirmEmail', body);
export const verifyEmail = (body) => post('/users/verifyEmail', body);
export const editAccountInformation = (body) => patch('/users', body);
export const getUser = () => get('/users/currentUser');
export const getUserByUserName = (body) => post('/users/getUserByUserName', body);
export const forgotPassword = (body) => post('/users/forgotPassword', body);
export const checkForgotPasswordToken = (body) => post('/users/checkForgotPasswordToken', body);
export const changeUserPassword = (body) => post('/users/changePassword', body);
export const changeUserPasswordByOldPass = (body) => post('/users/changePasswordByOldPass', body);
export const findUserByEmail = (body) => post('/users/findUserByEmail', body);
export const sendDeleteLink = (body) => post('/users/sendDeleteLink', body);
export const getZoomCredentials = (body) => post('/users/getZoomCredentials', body);
export const disconnectZoom = () => get('/users/disconnectZoom');
export const disconnectGoogle = (body) => post('/users/disconnectGoogle', body);
export const disconnectGoogleCalendar = (body) => post('/users/disconnectGoogleCalendar', body);
export const deleteTeamMember = (body) => post('/users/deleteTeamMember', body);
export const changeUserRole = (body) => post('/users/changeUserRole', body);
export const getGoogleEvents = ({ id }) => get(`/users/getGoogleEvents/${id}`);
export const saveGoogleEvents = () => get('/users/saveGoogleEvents');
export const getGoogleEventsByUserName = (body) => post('/users/getGoogleEventsByUserName', body);
export const updateUserTimeZone = (body) => patch('users/updateUserTimeZone', body);
export const getUserByUserId = (body) => post('/users/getUserByUserId', body);
export const toggleTeamMemberStatus = (body) => post('/users/toggleTeamMemberStatus', body);

/* meetings requests */
export const saveMeeting = (body) => post('/meetings', body);
export const loadMeetings = () => get(`/meetings/getAllAttachedMeetings`);
export const loadAllAttachedMeetingsByUserId = (body) => post('/meetings/getMeetingsByUserId', body);
export const updateMeeting = (data) => patch(`/meetings/${data.id}/editAttachedMeeting`, data.body);
export const changeAboutMeAndTagline = (body) => patch('/meetings/changeAboutMeAndTagline', body);
export const loadMeetingById = (id) => get(`/meetings/${id}/getAttachedMeeting`);
export const loadMeetingByMeetingRoute = (body) => post(`/meetings/meetingByRoute`, body);
export const deleteMeetingById = (id) => httpDelete(`/meetings/${id}/deleteAttachedMeeting`);
export const toggleAssignAll = (body) => post('/meetings/toggleAssignAll', body);
export const updateMeetingTeamMemberData = (body) => patch('/meetings/updateMeetingTeamMemberData', body);
export const updateTeamMemberAssignOnDisable = (body) => post('/meetings/updateTeamMemberAssignOnDisable', body);

/* orders requests */
export const getOrdersByUserId = ({ id }) => get(`/orders/${id}`);
export const getAllOrdersByOwnerId = ({ id }) => get(`/orders/getAllOrdersByOwnerId/${id}`);
export const getAllNotifications = () => get('/notifications');
export const getOrdersByMeetingRoute = (body) => post('/orders/getOrdersByMeetingRoute', body);
export const getOrdersByUsername = (body) => post('/orders/getOrdersByUserName', body);
export const addParticipantToOrder = (body) => patch('/orders/addNewCustomer', body);
export const findOneOrder = (body) => post('/orders/findOneOrder', body);
export const updateOrderFieldsById = (body) => patch('/orders/editOrderData', body);
export const deleteOrderById = (body) => httpDelete('/orders/deleteOrder', body);
export const removeCustomer = (body) => patch('/orders/removeCustomerById', body);
export const checkAvailabilityOfTime = (body) => post('/orders/checkAvailabilityOfTime', body);
export const paymentToMeeting = (body) => post('/orders/paymentToMeeting', body);
export const getNonBookedTimesByUsersId = (body) => post('/orders/getNonBookedTimesByUsersId', body);

/* customer requests */
export const getCustomersByOwnerId = ({ ownerId }) => get(`/customers/byOwner/${ownerId}`);
export const createCustomer = (body) => post('/customers/createCustomer', body);
export const importCustomers = (body) => post('/customers/importCustomers', body);
export const getCustomerById = ({ customerId }) => get(`/customers/${customerId}`);
// Find a customer based on criteria (e.g., search by email)
export const findCustomer = (criteria) => post('/customers/find', { criteria });
export const updateCustomer = async ({ customerId, updateData }) => {
  try {
    const response = await patch(`/customers/${customerId}`, {updateFields: updateData});
    return response.message;  // Assuming the server response structure is { data: customerData }
  } catch (error) {
    console.error('API Error:', error.response || error.message);
    throw error;  // Rethrowing the error to be handled by the caller
  }
};
export const updateStatusStructureByOwnerId = async ({ ownerId, statuses }) => {
  try {
    const response = await patch(`/customers/updateStatusStructureByOwnerId/${ownerId}`, { statuses });
    return response;
  } catch (error) {
    console.error('API Error:', error.response || error.message);
    throw error;
  }
};
export const updatePrioritiesStructureByOwnerId = async ({ ownerId, priorities }) => {
  try {
    const response = await patch(`/customers/updatePrioritiesStructureByOwnerId/${ownerId}`, { priorities });
    return response;
  } catch (error) {
    console.error('API Error:', error.response || error.message);
    throw error;
  }
};

export const deleteCustomer = ({ customerId }) => httpDelete(`/customers/${customerId}`);

/* note requests */
export const fetchNoteById = (noteId) => get(`/notes/${noteId}`);
export const fetchNotesByCustomerId = (customerId) => get(`/notes/byCustomer/${customerId}`);
export const createNote = async (body) => {
  try {
    const response = await post('/notes', body);
    return response;
  } catch (error) {
    console.error('Failed to post note:', error);
    throw error;
  }
};
export const updateNote = (noteId, updateData) => patch(`/notes/${noteId}`, updateData);
export const deleteNote = (noteId) => httpDelete(`/notes/${noteId}`);

/* activites requests */
export const fetchActivitiesByCustomerId = (customerId) => get(`/activities/byCustomer/${customerId}`);

/* task requests */
export const fetchTaskById = (taskId) => get(`/tasks/${taskId}`);
export const fetchTasksByCustomerId = (customerId) => get(`/tasks/byCustomer/${customerId}`);
export const createTask = async (body) => {
  try {
    const response = await post('/tasks', body);
    return response;
  } catch (error) {
    console.error('Failed to post note:', error);
    throw error;
  }
};
// services/api.js
export const updateTask = (taskId, updateData, task) => patch(`/tasks/${taskId}`, { updateData, task });
export const deleteTask = (taskId) => httpDelete(`/tasks/${taskId}`);

/* Email Meeting Requests */
export const sendCustomEmail = (body) => post('/notifications/sendCustomEmail', body);

/* custom orders requests */
export const getCustomOrdersByUserId = ({ id }) => get(`/customOrders/${id}`);
export const createCustomOrder = (body) => post('/customOrders', body);
export const updateCustomOrderById = ({ orderId, userId, updatedFields }) =>
  patch(`/customOrders/${orderId}/${userId}`, updatedFields);
export const addParticipant = ({ orderId, data }) => post(`/customOrders/${orderId}/addCustomer`, data);
export const deleteParticipant = ({ orderId, customerId }) => httpDelete(`/customOrders/${orderId}/${customerId}`);
export const deleteCustomOrder = ({ id, userId }) => httpDelete(`/customOrders/${id}/${userId}`);

/**
 *@deprecated, the entire logic of creating a new Order has been transferred to 'paymentToMeeting'
 */
export const saveOrder = (body) => post('/orders', body);

/* notifications requests */
export const updateViewedNotifications = (body) => patch('/notifications/updateNotifications', body);
export const sendOverlappingOrdersNotifications = (body) =>
  post('/notifications/sendOverlappingOrdersNotifications', body);

/*youtube api requests*/
export const getVideoInfo = (videoId, API_KEY) =>
  fetch(`https://www.googleapis.com/youtube/v3/videos?key=${API_KEY}&part=snippet&id=${videoId}`, { method: 'GET' });

/* OpenStreetMap api requests*/
export const getCoordinates = (query) =>
  fetch(`https://nominatim.openstreetmap.org/?addressdetails=1&q=${query}&format=json&limit=1`, {
    method: 'GET',
  });

/*payment api requests*/
export const createCheckoutSession = (body) => post('/payments/create-checkout-session', body);
export const saveBillingPortalUrl = (body) => post('/payments/saveBillingPortalUrl', body);
export const toggleCancelSubscription = (body) => post('/payments/toggleCancelSubscription', body);
export const upgradeBasicToPro = (body) => post('/payments/upgrade-basic-to-pro', body);
export const upgradeSubscription = (body) => post('/payments/upgradeSubscription', body);
export const upgradeProSubscription = (body) => post('/payments/upgrade-pro-subscription', body);
export const getInvoiceUrl = () => post('/payments/invoicePage');
