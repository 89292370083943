import { google } from 'constants/google.const';
import { SOCIAL_PLATFORMS } from 'constants/social.const.js';
import { showErrorMessage } from 'helpers/errors';
import { isVendor } from 'helpers/roleHelper';
import usePlan from 'hooks/usePlan';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Row } from 'reactstrap';
import Google from 'sharedComponents/auth/signup/iconsComponents/Google';
import NavBrand from 'sharedComponents/navbars/partials/NavBrand';
import { userDataSelector } from 'store/selectors';

const defaultSocialButtons = [SOCIAL_PLATFORMS.GOOGLE, SOCIAL_PLATFORMS.OUTLOOK];

const ConnectAccount = ({ buttons = defaultSocialButtons }) => {
  const navigate = useNavigate();
  const { isFreePlan, isActivePlan } = usePlan();
  const userSelector = useSelector(userDataSelector);
  const googleConnectError = userSelector[google.CONNECT_ERROR];
  const { user } = userSelector;

  useEffect(() => {
    if (user.googleEmail) {
      if (!isVendor(user.roles[0]?.name) && isActivePlan) return navigate('/tour');
      navigate(isFreePlan ? '/upgraded' : isActivePlan ? '/pages' : '/upgrade');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const socialLoginButtons = {
    [SOCIAL_PLATFORMS.GOOGLE]: (
      <Google text={'Continue with Google'} typeOfButton={google.CONNECT} redirect={'/connectaccount'} />
    ),
  };
  const handleSkipClick = () => {
    if (!isVendor(user.roles[0]?.name) && isActivePlan) return navigate('/tour');

    navigate(isFreePlan ? '/upgraded' : isActivePlan ? '/pages' : '/upgrade');
  };

  return (
    <>
      <nav
        id="navbar-main"
        className="navbar-horizontal navbar-main navbar navbar-expand-lg justify-content-left row p-0 zindex-1 position-absolute w-100 mx-0"
      >
        <Col className="col-lg-12 col-sm-12 d-flex justify-content-center bg-white col p-2 shadow ">
          <NavBrand isTransparent={false} />
        </Col>
      </nav>
      <div className="container-fluid">
        <Row className="min-vh-100-50">
          <Col className="order-lg-2 col-lg-5 col-sm-12 d-sm-none flex-grow-1 d-lg-flex align-items-center justify-content-center">
            <div className="onboarding-image mw-600-sm">
              <img src={'images/image5.svg'} alt={'img-connect-calendar'} />
            </div>
          </Col>

          <Col className="order-lg-1 col-lg-7 col-sm-12 flex-grow-1 d-flex align-items-center justify-content-center bg-white">
            <Form role="form" className="mw-500-sm">
              <Row>
                <Col className="col-sm-12 d-lg-block">
                  <h1 className="h1 text-center">Connect Your Calendar</h1>
                  <p className="text-center">
                    Connect your calendar to get notified about your appointments.
                    <br />
                    Also, sync your current schedule with Weshare.
                  </p>
                  <div className="btn-wrapper">
                    {buttons.map((button) => socialLoginButtons[button])}
                    {googleConnectError && showErrorMessage(googleConnectError)}
                    <Button
                      type="button"
                      color="outline-secondary"
                      className="btn-block"
                      size="xl"
                      onClick={handleSkipClick}
                    >
                      Skip
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ConnectAccount;
