import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { customSolutionId, inPersonMeetingId } from 'constants/meeting.const';
import * as api from 'services/api';
import { asyncActionsCreator } from 'store/reducersHelper';
import { removeCustomer } from './thunks/editOrderThunks';

export const saveOrder = createAsyncThunk('orders/saveOrder', async (orderData) => await api.saveOrder(orderData));

export const loadOrdersByMeetingRoute = createAsyncThunk(
  'orders/getOrdersByMeetingRoute',
  async (meetingRoute) => await api.getOrdersByMeetingRoute(meetingRoute),
);

export const loadOrdersByUserId = createAsyncThunk(
  'orders/loadOrdersByUserId',
  async (data) => await api.getOrdersByUserId(data),
);

export const getAllOrdersByOwnerId = createAsyncThunk(
  'orders/getAllOrdersByOwnerId',
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.getAllOrdersByOwnerId(id)
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const loadCustomOrdersByUserId = createAsyncThunk(
  'customOrders/loadCustomOrdersByUserId',
  async (data) => await api.getCustomOrdersByUserId(data),
);

export const loadOrdersByUsername = createAsyncThunk(
  'orders/loadOrdersByUsername',
  async (orderData) => await api.getOrdersByUsername(orderData),
);

export const loadNotifications = createAsyncThunk(
  'orders/loadNotifications',
  async () => await api.getAllNotifications(),
);

export const addNewCustomerToOrder = createAsyncThunk(
  'orders/addNewCustomerToOrder',
  async (orderData) => await api.addParticipantToOrder(orderData),
);

export const findOneOrder = createAsyncThunk('orders/findOneOrder', async (orderData) => api.findOneOrder(orderData));

export const updateOrderById = createAsyncThunk(
  'orders/updateOrderById',
  async (orderData) => await api.updateOrderFieldsById(orderData),
);

export const createCustomOrder = createAsyncThunk(
  'customOrders/createCustomOrder',
  async (orderData) => await api.createCustomOrder(orderData),
);

export const updateCustomOrderById = createAsyncThunk(
  'customOrders/updateCustomOrderById',
  async (orderData) => await api.updateCustomOrderById(orderData),
);
export const addParticipant = createAsyncThunk(
  'customOrders/addCustomer',
  async (orderData) => await api.addParticipant(orderData),
);
export const deleteParticipant = createAsyncThunk('customOrders/deleteCustomer', async (orderData, { dispatch }) => {
  const payload = await api.deleteParticipant(orderData);
  payload.updatedOrder && dispatch(setCurrentOrder(payload.updatedOrder));
  return payload;
});

export const deleteOrderById = createAsyncThunk(
  'orders/deleteOrderById',
  async (orderId) => await api.deleteOrderById(orderId),
);
export const deleteCustomOrder = createAsyncThunk(
  'customOrders/deleteCustomOrder',
  async (data) => await api.deleteCustomOrder(data),
);

export const updateViewedNotifications = createAsyncThunk(
  'orders/updateViewedNotifications',
  async (notificationsId) => await api.updateViewedNotifications(notificationsId),
);

export const sendOverlappingOrdersNotifications = createAsyncThunk(
  'orders/sendOverlappingOrdersNotifications',
  async (ordersId) => await api.sendOverlappingOrdersNotifications(ordersId),
);

export const sendCustomEmail = createAsyncThunk(
  'orders/sendCustomEmail',
  async (data) => await api.sendCustomEmail(data),
);

export const checkAvailabilityOfTime = createAsyncThunk(
  'orders/checkAvailabilityOfTime',
  async (checkTimeOrderData) => await api.checkAvailabilityOfTime(checkTimeOrderData),
);

export const paymentToMeeting = createAsyncThunk(
  'orders/paymentToMeeting',
  async (paymentData) => await api.paymentToMeeting(paymentData),
);
const initialState = {
  orders: [],
  customOrders: [],
  currentOrder: null,
  isExistOrder: null,
  notifications: [],
  isTimeAvailable: true,
  isUpdateOrderSuccess: false,
  isLoadOrdersByUserIdLoading: true,
};
export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setCurrentOrder: (state, { payload }) => {
      state.currentOrder = payload;
    },
    setExistOrder: (state, { payload }) => {
      state.isExistOrder = payload;
    },
    addOrder: (state, { payload }) => {
      state.orders.push(payload);
    },
    updateOrder: (state, { payload }) => {
      const index = state.orders.findIndex((order) => order._id === payload.updatedOrder._id);
      state.orders[index] = payload.updatedOrder;
    },
    updateOrderLocation: (state, { payload }) => {
      const { newOrderDate, orderId, newLocation } = payload;
      const index = state.orders.findIndex((order) => order._id === orderId);
      const currentOrder = state.orders[index];

      if (currentOrder) {
        const { teamMembers } = currentOrder.meetingId;

        currentOrder.orderDate = newOrderDate;
        if (teamMembers[0].location.locationId === customSolutionId) {
          teamMembers[0].location.customLocationUrl = newLocation;
        }
        if (teamMembers[0].location.locationId === inPersonMeetingId) {
          teamMembers[0].location.inPersonLocation = newLocation;
        }
      }
    },
    updateNotifications: (state, { payload }) => {
      state.notifications.push(payload);
    },
    setIsUpdateOrderSuccess: (state, { payload }) => {
      state.isUpdateOrderSuccess = payload;
    },
    setIsTimeAvailable: (state, { payload }) => {
      state.isTimeAvailable = payload;
    },
    resetOrders: () => initialState,
  },
  extraReducers: {
    ...asyncActionsCreator(saveOrder, 'saveOrder', {
      fulfilled: (state, { payload }) => {
        state.orders.push(payload.order);
        state.currentOrder = null;
        state.isExistOrder = null;
      },
    }),

    ...asyncActionsCreator(loadNotifications, 'loadNotifications', {
      fulfilled: (state, { payload }) => {
        state.notifications = payload;
      },
    }),

    ...asyncActionsCreator(addNewCustomerToOrder, 'addNewCustomerToOrder', {
      fulfilled: (state, { payload }) => {
        state.orders = state.orders.map((order) =>
          payload.updatedOrder._id === order._id ? payload.updatedOrder : order,
        );
        state.currentOrder = null;
        state.isExistOrder = null;
      },
    }),

    ...asyncActionsCreator(
      loadOrdersByMeetingRoute,
      'loadOrdersByMeetingRoute',
      {
        fulfilled: (state, { payload }) => {
          state.orders = payload.orders;
          state.isLoadOrdersByMeetingRouteLoading = false;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(
      loadOrdersByUsername,
      'loadOrdersByUsername',
      {
        fulfilled: (state, { payload }) => {
          state.orders = payload.orders;
          state.isLoadOrdersByUsernameAndDayLoading = false;
        },
      },
      { loadingHandler: true },
    ),
    ...asyncActionsCreator(
      getAllOrdersByOwnerId, 
      'getAllOrdersByOwnerId', 
      {
      fulfilled: (state, action) => {
          state.orders = action.payload.orders || [];
          state.customOrders = action.payload.customOrders || [];
      }
    }, 
    { loadingHandler: true },
  ),
    ...asyncActionsCreator(
      loadOrdersByUserId,
      'loadOrdersByUserId',
      {
        fulfilled: (state, { payload }) => {
          state.orders = payload.orders;
          state.isLoadOrdersByUserIdLoading = false;
        },
      },
      { loadingHandler: true },
    ),
    ...asyncActionsCreator(loadCustomOrdersByUserId, 'loadCustomOrdersByUserId', {
      fulfilled: (state, { payload }) => {
        state.customOrders = payload.customOrders;
      },
    }),

    ...asyncActionsCreator(
      updateOrderById,
      'updateOrderById',
      {
        fulfilled: (state, { payload }) => {
          state.orders = state.orders.map((order) => {
            return order._id === payload.updatedOrder._id ? payload.updatedOrder : order;
          });
          state.isUpdateOrderByIdLoading = false;
          state.isUpdateOrderSuccess = true;
        },
      },
      { loadingHandler: true },
    ),
    ...asyncActionsCreator(updateCustomOrderById, 'updateCustomOrderById', {
      fulfilled: (state, { payload }) => {
        state.customOrders = state.customOrders.map((order) => {
          return order._id === payload.customOrder._id ? payload.customOrder : order;
        });
      },
    }),

    ...asyncActionsCreator(addParticipant, 'addCustomer', {
      fulfilled: (state, { payload }) => {
        if (state.customOrders && payload.updatedOrder) {
          state.customOrders = state.customOrders.map((order) =>
            payload.updatedOrder._id === order._id ? payload.updatedOrder : order
          );
        }
      },
    }),

    ...asyncActionsCreator(deleteParticipant, 'deleteCustomer d d', {
      fulfilled: (state, { payload }) => {
        state.customOrders = state.customOrders.map((order) =>
          payload.updatedOrder._id === order._id ? payload.updatedOrder : order,
        );
      },
    }),

    ...asyncActionsCreator(
      deleteOrderById,
      'deleteOrderById',
      {
        fulfilled: (state, { payload }) => {
          state.orders = state.orders.filter((order) => order._id !== payload.deletedOrder._id);
          state.isDeleteOrderByIdLoading = false;
        },
      },
      { loadingHandler: true },
    ),
    ...asyncActionsCreator(deleteCustomOrder, 'deleteCustomOrder', {
      fulfilled: (state, { payload }) => {
        state.customOrders = current(state.customOrders).filter(
          (order) => order._id !== payload.deletedCustomOrder._id,
        );
      },
    }),
    ...asyncActionsCreator(createCustomOrder, 'createCustomOrder', {
      fulfilled: (state, { payload }) => {
        state.customOrders.push(payload.order);
      },
    }),

    ...asyncActionsCreator(updateViewedNotifications, 'updateViewedNotifications', {
      fulfilled: (state, { payload }) => {
        state.notifications = payload;
      },
    }),
    ...asyncActionsCreator(findOneOrder, 'findOneOrder', {}, { loadingHandler: true }),

    ...asyncActionsCreator(
      removeCustomer,
      'removeCustomer',
      {
        fulfilled: (state, { payload }) => {
          const indexOfUpdatedOrder = state.orders.findIndex((order) => order._id === payload.updatedOrder._id);
          state.orders[indexOfUpdatedOrder] = payload.updatedOrder;
          state.isRemoveCustomerByIdLoading = false;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(paymentToMeeting, 'paymentToMeeting', {
      fulfilled: (state, { payload }) => {
        if (payload.updatedOrder) {
          state.orders = state.orders.map((order) =>
            payload.updatedOrder._id === order._id ? payload.updatedOrder : order,
          );
          state.currentOrder = payload.updatedOrder;
          state.isExistOrder = null;
        }

        if (payload.order) {
          state.orders.push(payload.order);
          state.currentOrder = payload.order;
          state.isExistOrder = null;
        }
      },
    }),
   
  },
});

export const {
  setIsUpdateOrderSuccess,
  setCurrentOrder,
  setExistOrder,
  addOrder,
  updateOrder,
  updateNotifications,
  setIsTimeAvailable,
  resetOrders,
  updateOrderLocation,
} = ordersSlice.actions;

export default ordersSlice.reducer;
