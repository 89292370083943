import 'moment-timezone';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import IndexFooter from 'sharedComponents/footers/IndexFooter';
import CrownSvg from 'sharedComponents/icons/CrownSvg';
import RectangleSvg from 'sharedComponents/icons/RectangleSvg';
import SquareSvg from 'sharedComponents/icons/SquareSvg';
import AddNavbar from 'sharedComponents/navbars/AddNavbar';
import { plansDefaults } from '../../constants/plan.const';
import { createCheckoutSession } from '../../store/reducers/userReducer';

const StyledRectangleSvg = () => (
  <RectangleSvg width="16" height="26" fill="#8898aa" className="mr-1" style={{ verticalAlign: 'top' }} />
);

const Upgrade = () => {
  const [isMonthly, setIsMonthly] = useState(false);
  const [numberOfUsers, setNumberOfUsers] = useState(1);
  const [inputNumberOfUsers, setInputNumberOfUsers] = useState(1);
  const [pricePerUser, setPricePerUser] = useState(plansDefaults.basicPriceYearlyPerMonth);
  const [pricePerTeamMember, setPricePerTeamMember] = useState(plansDefaults.proPricePerMemberYearly);
  const dispatch = useDispatch();

  const handleBillingCycleChange = (bool) => {
    setIsMonthly(bool);
    bool ? setPricePerUser(plansDefaults.basicPriceMonthly) : setPricePerUser(plansDefaults.basicPriceYearlyPerMonth);
    bool
      ? setPricePerTeamMember(plansDefaults.proPricePerMemberMonthly)
      : setPricePerTeamMember(plansDefaults.proPricePerMemberYearly);
  };

  const handleUsersChange = (e) => {
    setInputNumberOfUsers(Number(e.target.value));
    setNumberOfUsers(Number(e.target.value));
  };

  const setUserQuantity = (planName) => {
    if (planName === 'basic') return 1;
    if (planName === 'pro' && inputNumberOfUsers === 1) return 2;
    return inputNumberOfUsers;
  };

  const handleStartTrial = async (planName) => {
    try {
      const response = await dispatch(
        createCheckoutSession({ plan: isMonthly ? 'month' : 'year', quantity: setUserQuantity(planName) }),
      );
      window.location.replace(response.payload.url);
    } catch (error) {
      alert('You already have a plan, upgrade or downgrade');
    }
  };

  return (
    <>
      <div>
        <AddNavbar />
        <div className="bg-white">
          <Container className="py-3 px-3 top-0">
            <Row className="justify-content-center">
              <Col sm="12" className="mb-5 mt-3">
                <h1 className="display-2 text-center">Try it free for 30 days</h1>
                <h2 className="h2 font-weight-normal text-muted text-center">Start small or scale as you need.</h2>
              </Col>
              <Col sm="12" className="mb-3 d-flex justify-content-center align-items-center ml-6">
                <h2 className="h1 mr-2">How many users are on your team?</h2>
                <input
                  type="number"
                  className="form-control-lg border-premium text-lg"
                  min="1"
                  max="10000"
                  style={{ width: '120px', boxShadow: 'none' }}
                  value={inputNumberOfUsers}
                  onChange={handleUsersChange}
                ></input>
              </Col>
              <Col sm="12" className="mb-3 d-flex justify-content-center align-items-center">
                <ButtonGroup aria-label="Plan Duration">
                  <Button
                    variant="secondary"
                    className={isMonthly ? 'bg-gradient-premium border-0 text-white' : ''}
                    onClick={() => handleBillingCycleChange(true)}
                  >
                    Billed Monthly
                  </Button>
                  <Button
                    variant="secondary"
                    className={isMonthly ? 'border-left' : 'bg-gradient-premium border-0 border-left text-white'}
                    onClick={() => handleBillingCycleChange(false)}
                  >
                    Billed Annually (30% OFF)
                  </Button>
                </ButtonGroup>
              </Col>
              <Col lg="9" className="my-5">
                <div className="pricing card-group flex-column flex-md-row mb-3">
                  <Card
                    className={
                      'card-pricing shadow-lg rounded-lg border-0 text-center mb-4 p-0 overflow-hidden mw-500-sm mx-sm-auto mr-md-4'
                    }
                  >
                    <CardHeader className="bg-transparent pt-4 pb-3">
                      <h2 className="ls-1 py-2 mb-0">
                        <span style={{ verticalAlign: 'top' }}>Basic</span>
                      </h2>
                    </CardHeader>
                    <CardBody className="px-lg-5">
                      <div className="display-1">
                        <sup className="text-sm">US$</sup>
                        {pricePerUser}
                        <sub className="h3 d-block" style={{ bottom: '10px' }}>
                          /Month
                        </sub>
                      </div>

                      {/* {!isMonthly ? (
                        <>
                          <span>
                            Billed at <strike>${pricePerUserMonthly * 12}</strike> $
                            {pricePerUser * 12}/year
                          </span>
                        </>
                      ) : (
                        <></>
                      )} */}

                      <Button
                        className="btn-block btn-lg mt-3 btn-outline-secondary border-light shadow-none"
                        type="button"
                        onClick={() => handleStartTrial('basic')}
                      >
                        Start Free Trial <i className="fas fa-arrow-right ml-1" />
                      </Button>
                      <p className="pt-2 mb-0">
                        <span className="font-weight-bold">Includes 1 User</span>
                        <br />{' '}
                      </p>
                      <ul className="list-unstyled mb-4 mt-6 text-left mw-400-sm">
                        <li>
                          <div>
                            <p className="mb-0 text-sm">
                              <StyledRectangleSvg /> <span>Try It Free For 30 Days</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="mb-0 text-sm">
                              <StyledRectangleSvg /> <span>Schedule Unlimited Appointments</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="mb-0 text-sm">
                              <StyledRectangleSvg /> <span>Unlimited Lead Capturing</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="mb-0 text-sm">
                              <StyledRectangleSvg /> <span>Automated Appointment Notifications</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="mb-0 text-sm">
                              <StyledRectangleSvg /> <span>1-On-1 & Group Appointments</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="mb-0 text-sm">
                              <StyledRectangleSvg />{' '}
                              <span>Integrations - Google Meet, Google Maps, Google Calendar etc.</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="mb-0 text-sm">
                              <StyledRectangleSvg /> <span>Landing Page Builder</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="mb-0 text-sm">
                              <StyledRectangleSvg /> <span>14-day Money Back Guarantee</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                  <Card className={'card-pricing shadow-lg rounded-lg border-0 text-center mb-4 p-0 mx-auto mw-500-sm'}>
                    <div className="ribbon-corner">Most Popular</div>
                    <CardHeader className="bg-transparent pt-4 pb-3">
                      <h2 className="ls-1 py-2 mb-0">
                        <CrownSvg />
                        <span style={{ verticalAlign: 'top' }}>Pro</span>
                      </h2>
                    </CardHeader>
                    <CardBody className="px-lg-5">
                      <div className="display-1">
                        <sup className="text-sm">US$</sup>
                        {pricePerTeamMember}
                        <sub className="h3 d-block" style={{ bottom: '10px' }}>
                          User/Month
                        </sub>
                      </div>

                      {/* {!isMonthly ? (
                        <>
                          <span>
                            Billed at <strike>${(plansDefaults.proPricePerMemberMonthly * numberOfUsers) * 12}</strike> $
                            {pricePerTeamMember * numberOfUsers * 12}/year
                          </span>
                        </>
                      ) : (
                        <span>
                            Billed at ${plansDefaults.proPricePerMemberMonthly * numberOfUsers}/month
                          </span>
                      )} */}

                      <Button
                        className="btn-block btn-lg mt-3 bg-gradient-premium border-0 text-white"
                        type="button"
                        onClick={() => handleStartTrial('pro')}
                      >
                        Start Free Trial <i className="fas fa-arrow-right ml-1" />
                      </Button>
                      <p className="pt-2 mb-0">
                        <span className="font-weight-bold">
                          Includes {inputNumberOfUsers === 1 ? '2+' : numberOfUsers} Users
                        </span>
                        <br />{' '}
                      </p>
                      <p className="mt-6 mb-2 text-left">
                        <span className="font-weight-bold">Everything in Basic Plan Plus:</span>
                        <br />{' '}
                      </p>
                      <ul className="list-unstyled mb-4 mt-0 text-left mw-400-sm">
                        <li>
                          <div>
                            <p className="mb-0 text-sm">
                              <StyledRectangleSvg /> <span>Try It Free For 30 Days</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="mb-0 text-sm">
                              <StyledRectangleSvg /> <span>Remove Weshare Branding</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="mb-0 text-sm">
                              <StyledRectangleSvg /> <span>All-In-One Calendar Management For Teams</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="mb-0 text-sm">
                              <StyledRectangleSvg />{' '}
                              <span>Round-robin Appointments Based on Priority or Custom Rules</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="mb-0 text-sm">
                              <StyledRectangleSvg /> <span>Assign Team Managers & Team Members.</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="mb-0 text-sm">
                              <StyledRectangleSvg /> <span>Real-time Routing For Leads & Prospects</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="mb-0 text-sm">
                              <StyledRectangleSvg /> <span>14-day Money Back Guarantee</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-lg-center px-3 mb-3">
              <div>
                <div className="icon icon-shape bg-white shadow rounded-circle text-primary">
                  <img src="images/premium.svg" alt="" />
                </div>
              </div>
              <Col lg="6">
                <p>
                  <strong>Weshare Basic & Pro</strong> are best for businesses, teams & individuals who need advanced
                  scheduling and management capabilities.
                </p>
              </Col>
            </div>
            <Row className="row-grid justify-content-center">
              <div className="col-xl-12">
                <div className="table-container">
                  <table className="price-table font-family-5">
                    <tbody>
                      <tr className="price-table-head no-left-border">
                        <td></td>

                        <td className="green-width border-top">
                          <span className="font-weight-bold">Basic</span>
                          <br />
                          <small className="text-sm">Best For Individuals</small>
                        </td>
                        <td className="green-width price-table-popular">
                          <div>
                            <CrownSvg />
                            <span className="font-weight-bold">Pro</span>
                            <br />
                            <small className="text-sm">Best For Businesses & Teams</small>
                          </div>
                        </td>
                      </tr>
                      <tr className="price-table-head-pricing no-left-border border-bottom-0">
                        <td className="align-items-end"></td>

                        <td className="price">
                          {!isMonthly ? (
                            <>
                              <sup>US$</sup>
                              <span className="pro-price-monthly">{pricePerUser}</span>
                              /Month
                              <p className="text-sm mb-0 mt-2">
                                Billed at <strike>${plansDefaults.basicPriceMonthly * 12}</strike> ${pricePerUser * 12}
                                /year
                              </p>
                            </>
                          ) : (
                            <>
                              <sup>US$</sup>
                              <span className="pro-price-monthly">{pricePerUser}</span>
                              /Month <p className="text-sm mb-0 mt-2">Billed at ${pricePerUser}/month</p>
                            </>
                          )}

                          <Button
                            className="btn-outline-secondary btn-lg rounded font-size-5 font-weight-bold my-2 px-4 border-light shadow-none"
                            onClick={() => handleStartTrial('basic')}
                          >
                            Start Free Trial <i className="fa fa-arrow-right ml-1" />
                          </Button>
                        </td>
                        <td className="price">
                          {!isMonthly ? (
                            <>
                              <sup>US$</sup>
                              <span className="pro-price-monthly">{pricePerTeamMember}</span>
                              /User/Month
                              <p className="text-sm mb-0 mt-2">
                                Billed at{' '}
                                <strike>${plansDefaults.proPricePerMemberMonthly * numberOfUsers * 12}</strike> $
                                {pricePerTeamMember * numberOfUsers * 12}/year
                              </p>
                            </>
                          ) : (
                            <>
                              <sup>US$</sup>
                              <span className="pro-price-monthly">{plansDefaults.proPricePerMemberMonthly}</span>
                              /User/Month{' '}
                              <p className="text-sm mb-0 mt-2">
                                Billed at ${plansDefaults.proPricePerMemberMonthly * numberOfUsers}/month
                              </p>
                            </>
                          )}

                          <Button
                            className="btn-highlight btn-lg rounded bg-gradient-premium border-0 font-size-5 font-weight-bold my-2 px-4 text-white"
                            onClick={() => handleStartTrial('pro')}
                          >
                            Start Free Trial <i className="fa fa-arrow-right ml-1" />
                          </Button>
                        </td>
                      </tr>
                      <tr className="border-left-0">
                        <td className="border-0">
                          <h4 className="text-muted mb-0">CORE FEATURES</h4>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Amount Of Users{' '}
                            <div
                              className="tooltip-down"
                              data-title="Full featured Calendar that allows you to manage all your calendars from a single place."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>1 Paid User</td>
                        <td>
                          <span className="font-weight-bold">
                            {inputNumberOfUsers > 1 ? (
                              <>
                                <input
                                  type="number"
                                  className="form-control border-premium d-inline-block"
                                  min="2"
                                  max="10000"
                                  style={{ width: '75px', boxShadow: 'none' }}
                                  value={inputNumberOfUsers}
                                  onChange={handleUsersChange}
                                ></input>{' '}
                                Paid Users
                              </>
                            ) : (
                              <>2+ Paid Users</>
                            )}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Unlimited Appointment Pages{' '}
                            <div
                              className="tooltip-down"
                              data-title="Let clients book your services, appointments, sessions, classes, workshops &amp; more."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Calendar Connections{' '}
                            <div
                              className="tooltip-down"
                              data-title="Full featured Calendar that allows you to manage all your calendars from a single place."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>1 Calendar</td>
                        <td>1 Calendar Per User</td>
                      </tr>

                      {/*<tr>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-between">Remove Weshare ads
                                                <div className="tooltip-down" data-title="Remove &quot;Powered By Weshare&quot;
  ads and give your visitors a site experience that's entirely your own brand."><svg width="16" height="16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path fill="#D8D6E3" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"></path>
                                                    </svg></div>
                                            </div>
                                        </td>
                                        
                                        
                                                        <td><svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="18" fill="#8898aa"><path  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></td>
                                    </tr>*/}
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Schedule Unlimited Appointments{' '}
                            <div
                              className="tooltip-down"
                              data-title="Allow your customers to schedule appointments on your calendar without limitations."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>

                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Custom Landing Page with unique URL{' '}
                            <div
                              className="tooltip-down"
                              data-title="Reach out to new customers with a custom link that lets customers book an appointment with you."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            1-On-1 &amp; Group Appointment Types{' '}
                            <div
                              className="tooltip-down"
                              data-title="Create 1-on-1 sessions/private lessons or group workshops, events, webinars etc."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Unlimited Lead Capturing{' '}
                            <div
                              className="tooltip-down"
                              data-title="Capture lead's details and organize them in a table"
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Automated Appointment Reminders{' '}
                            <div
                              className="tooltip-down"
                              data-title="We make sure that your customers will actually show up on time! You can set up personalized reminders to ensure the appointment goes smoothly."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Customizable Thank You Page{' '}
                            <div
                              className="tooltip-down"
                              data-title="Customize your own thank you page that confirms the appointment. Add more details your customers should know about the appointment."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Landing Page Builder
                            <div
                              className="tooltip-down"
                              data-title="Create an appointment booking landing page. Add videos, images and text to make your landing page convert more visitors."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Customers Management{' '}
                            <div
                              className="tooltip-down"
                              data-title="Save all your customers details in a table. Export any data to a CSV file at any time."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Automatic Time Zone Conversion{' '}
                            <div
                              className="tooltip-down"
                              data-title="Allow customers from different parts of the world to book a time with you and show them when you're available automatically."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Re-scheduling &amp; Cancellations{' '}
                            <div
                              className="tooltip-down"
                              data-title="We will notify you for any changes in your schedule like overlapping, rescheduled or cancelled appointments."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr className="border-left-0">
                        <td className="border-0 pt-4">
                          <h4 className="text-muted mb-0">CUSTOMIZATION</h4>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Display your logo{' '}
                            <div
                              className="tooltip-down"
                              data-title="Have a dedicated profile with all of your appointment pages, embed your profile on your site or share it with a custom URL."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Brand your booking widget with your colors and copy{' '}
                            <div
                              className="tooltip-down"
                              data-title="Change the color of your booking widget or chatbot and use Weshare as an extension of your brand."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Remove Weshare Branding{' '}
                            <div
                              className="tooltip-down"
                              data-title="Full featured Calendar that allows you to manage all your calendars from a single place."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr className="border-left-0">
                        <td className="border-0 pt-4">
                          <h4 className="text-muted mb-0">INTEGRATIONS</h4>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Google Calendar 2-Way Sync{' '}
                            <div
                              className="tooltip-down"
                              data-title="Sync your Google Calendar with your Weshare full featured calendar so you don't book an appointment twice."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Google Meet &amp; Google Maps Integrations{' '}
                            <div
                              className="tooltip-down"
                              data-title="Connect Google Meet, Google Maps or any other video conference software to your appointment page. Once a customer book an appointment with you - we'll generate a unique meeting automatically for you."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Website integration (HTML){' '}
                            <div
                              className="tooltip-down"
                              data-title="Embed a booking widget or a booking chatbot on your site to enjoy appointment scheduling directly where you need it."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            CMS integration (WordPress etc.){' '}
                            <div
                              className="tooltip-down"
                              data-title="Embed a booking widget or a booking chatbot on your site to enjoy appointment scheduling directly where you need it."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr className="border-left-0">
                        <td className="border-0 pt-4">
                          <h4 className="text-muted mb-0">SUPPORT</h4>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Chat Support{' '}
                            <div
                              className="tooltip-down"
                              data-title="Get a support response by chatting with our new AI chatbot assistant."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Email support{' '}
                            <div className="tooltip-down" data-title="Get a support response by emailing us.">
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Knowledge Base Access{' '}
                            <div
                              className="tooltip-down"
                              data-title="Gain access to our knowledge base to find more answers to questions you might have."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      {/* <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            VIP Support{' '}
                            <div
                              className="tooltip-down"
                              data-title="Enjoy 24/7 priority support. Upgrade to a higher plan for VIP live chat support &amp; skip the line."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="16"
                            height="18"
                            fill="#8898aa"
                          >
                            <path
                              
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr> */}
                      <tr className="border-left-0">
                        <td className="border-0 pt-4">
                          <h4 className="text-muted mb-0">TEAMS</h4>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            All-In-One Calendar Management For Teams{' '}
                            <div
                              className="tooltip-down"
                              data-title="Manage all of your team members calendars including monitoring, rescheduling, cancelling appointments, and creating new custom appointments."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Round-robin Appointments Based on Priority or Custom Rules{' '}
                            <div
                              className="tooltip-down"
                              data-title="Make sure everyone is treated fairly by taking turns based on which team member is available next or based on a specific priority for each team member."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Assign Team Managers & Team Members{' '}
                            <div
                              className="tooltip-down"
                              data-title='Appoint Team Members to become "Team Managers" in order to help organize and take care of the team. They will oversight the account and make sure everyone knows what to do.'
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Real-time Routing For Leads & Prospects{' '}
                            <div
                              className="tooltip-down"
                              data-title="Quickly send appointment information to the right person who can help your lead or prospect. We're making sure that the right people are there to talk to your leads and prospects when they need it."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Edit Location & Available Times For Each Team Member{' '}
                            <div
                              className="tooltip-down"
                              data-title="Manage where and when each team member works, whether it's online or offline, and determine their individual availability."
                            >
                              <SquareSvg />
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <RectangleSvg width="16" height="18" fill="#8898aa" />
                        </td>
                      </tr>

                      <tr className="no-left-border">
                        <td></td>
                        <td className="price">
                          <Button
                            className="btn-outline-secondary btn-lg rounded font-size-5 font-weight-bold my-2 px-4 border-light shadow-none"
                            onClick={() => handleStartTrial('basic')}
                          >
                            Start Free Trial <i className="fa fa-arrow-right ml-1" />
                          </Button>
                        </td>
                        <td className="price">
                          <Button
                            className="btn-highlight btn-lg rounded bg-gradient-premium border-0 font-size-5 font-weight-bold my-2 px-4 text-white"
                            onClick={() => handleStartTrial('pro')}
                          >
                            Start Free Trial <i className="fa fa-arrow-right ml-1" />
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Row>
            <div className="d-flex justify-content-lg-center mt-5">
              <Col lg="10" className="p-0">
                <h2 className="display-4">Frequantly Asked Questions:</h2>
                <div className="mb-5">
                  <h3>What is Weshare Unlimited?</h3>
                  <p>
                    Schedule your next appointment with ease! With Weshare Premium plans, you can schedule one-on-one or
                    group appointments through customizable landing pages, capture leads and organize them in a CRM and
                    more. For best value we recommend an annual plan that discounts payments by up to 33% OFF.
                  </p>
                </div>
                <div className="mb-5">
                  <h3>Can I try it first?</h3>
                  <p>
                    Yes! Unlock all of Weshare's Premium features and if you decide it's not for you, you can cancel
                    within the first 14 days and receive a full refund - no questions asked.
                  </p>
                </div>
                <div className="mb-5">
                  <h3>Is Weshare a mobile or desktop app?</h3>
                  <p>
                    Weshare is an online app that you will access through your browser at weshare.net. Weshare is not on
                    the Apple App Store or Google Play. The online app is mobile friendly, so you can create appointment
                    pages (booking and landing pages) on-the-go from your phone or tablet.
                  </p>
                </div>

                <div className="mb-5">
                  <h3>Do you offer any discounted plans?</h3>
                  <p>Yes, we offer up to 33% discount on annual plans, when they are paid upfront.</p>
                </div>
                <div className="mb-5">
                  <h3>Do you offer setup assistance?</h3>
                  <p>
                    Of course – our team is here to help you get started. We offer weekly webinars, video tutorials,
                    step-by-step guides and personal 1:1 set up sessions.
                  </p>
                </div>

                <div className="mb-5">
                  <h3>Will I be able to change my subscription in the future?</h3>
                  <p>Yes, you may change your subscription at any time from yearly to monthly & vice versa.</p>
                </div>
                <div className="mb-5">
                  <h3>How do I upgrade or downgrade?</h3>
                  <p>
                    Visit your billing page by clicking on your profile picture and going to Settings {'>'} Billing.
                    From there, you can change your plan or update your payment information.
                  </p>
                </div>
              </Col>
            </div>
          </Container>
        </div>
      </div>
      <IndexFooter></IndexFooter>
    </>
  );
};

export default Upgrade;
