import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from 'store';
import App from './App';
import * as serviceWorker from './serviceWorker';
// bootstrap rtl for rtl support page
import 'assets/vendor/bootstrap-rtl/bootstrap-rtl.scss';
// plugins styles from node_modules
// import "react-notification-alert/dist/animate.css";
// import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/quill/dist/quill.core.css';
import 'assets/vendor/select2/dist/css/select2.min.css';
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css';
// core styles
import 'assets/scss/argon-dashboard-pro-react.scss?v1.1.0';
import './index.scss';
import './services/translations';

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <StrictMode>
        <App />
      </StrictMode>
    </Router>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
