import { showErrorMessage } from 'helpers/errors';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, InputGroup, Row } from 'reactstrap';
import AddNavbar from 'sharedComponents/navbars/AddNavbar';
import { verifyEmail } from 'store/reducers/authReducer';
import { authDataSelector, userDataSelector } from 'store/selectors';

const Confirmemail = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const authSelector = useSelector(authDataSelector);
  const userSelector = useSelector(userDataSelector);

  const [confirmCode, setConfirmCode] = useState(['', '', '', '', '', '']);
  const [validateError, setValidateError] = useState('');

  const confirmCode1Ref = useRef();
  const confirmCode2Ref = useRef();
  const confirmCode3Ref = useRef();
  const confirmCode4Ref = useRef();
  const confirmCode5Ref = useRef();
  const confirmCode6Ref = useRef();

  const refsArray = useMemo(
    () => [confirmCode1Ref, confirmCode2Ref, confirmCode3Ref, confirmCode4Ref, confirmCode5Ref, confirmCode6Ref],
    [],
  );

  const validate = () => {
    let isError = false;

    confirmCode.forEach((el) => {
      if (!el.value) {
        setValidateError('You need enter all 6 numbers');
        isError = true;
      }

      if (!Number.isInteger(+el.value) && !isError) {
        setValidateError('You can enter only numbers');
        isError = true;
      }
    });

    return isError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValidateError('');

    if (!validate()) {
      let code = '';

      confirmCode.forEach((el) => (code += el.value));
      dispatch(verifyEmail({ code, email: userSelector.email }));
    }
  };

  const handlePaste = (event) => {
    const text = (event.clipboardData || window.clipboardData).getData('text');

    if (text && new RegExp(/^[0-9]{6}$/).test(text)) {
      const textArr = text.split('');

      const confirmCode = textArr.map((el) => {
        return {
          value: el,
          isChange: false,
        };
      });

      setConfirmCode(confirmCode);
    }
  };

  const handleChange = ({ target }) => {
    const { value, name } = target;

    if (value.trim() || value === '') {
      setConfirmCode((prev) => {
        return [...prev].map((el, index) => {
          return {
            value: index === +name - 1 ? value : el.value,
            isChange: index === +name - 1,
          };
        });
      });
    }
  };

  const handleKeyPress = (e) => {
    const activeInputIndex = +e.target.name - 1;

    if (e.keyCode === 8) {
      setConfirmCode((prev) => {
        return [...prev].map((el, index) => {
          return {
            value: index === activeInputIndex ? '' : el.value,
            isChange: index === activeInputIndex,
          };
        });
      });
    }

    if (e.keyCode === 37 && activeInputIndex !== 0) {
      refsArray[activeInputIndex - 1].current.focus();
    }

    if (e.keyCode === 39 && activeInputIndex !== 5) {
      refsArray[activeInputIndex + 1].current.focus();
    }
  };

  useEffect(() => {
    confirmCode1Ref.current.focus();

    document.addEventListener('paste', handlePaste);

    return () => document.removeEventListener('paste', handlePaste);
  }, []);

  useEffect(() => {
    if (authSelector.isEmailVerified) {
      userSelector.isAuthSuccessfulWithEmail ? navigate('/addname') : navigate('/pages');
    }
  }, [authSelector.isEmailVerified, navigate, userSelector.isAuthSuccessfulWithEmail]);

  useEffect(() => {
    const changedElIndex = confirmCode.findIndex((el) => el.isChange === true);

    if (changedElIndex !== -1) {
      if (confirmCode[changedElIndex].value && changedElIndex !== 5) {
        refsArray[changedElIndex + 1].current.focus();
      }
    }
  }, [confirmCode]);

  return (
    <>
      <AddNavbar />
      <div className="container-fluid">
        <Row className="min-vh-100-50">
          <Col className="order-lg-2 col-lg-6 col-sm-12 d-sm-none flex-grow-1 d-lg-flex align-items-center justify-content-center">
            <div className="onboarding-image mw-600-sm">
              <img src={'images/image1.svg'} alt={'img'} />
            </div>
          </Col>

          <Col className="order-lg-1 col-lg-6 col-sm-12 flex-grow-1 d-flex align-items-center justify-content-center bg-white">
            <Form role="form" className="mw-500-sm" onSubmit={handleSubmit}>
              <Row>
                <Col className="col-sm-12 d-lg-block">
                  <h1 className="h1 text-center">Check your email for a code</h1>
                  <p className="text-center">
                    We’ve sent a 6-digit code to <strong>{userSelector.email || 'your email'}</strong>. The code expires
                    shortly, so please enter it soon.
                  </p>
                  <FormGroup className="mb-0">
                    <InputGroup>
                      <div className="d-flex align-items-center justify-content-center mb-0">
                        <div className="d-flex">
                          <div>
                            <Input
                              type="text"
                              name="1"
                              innerRef={confirmCode1Ref}
                              onChange={handleChange}
                              onKeyDown={handleKeyPress}
                              value={confirmCode[0].value}
                              maxLength="1"
                              aria-label="digit 1 of 6"
                              aria-disabled="false"
                              className=" form-control text-center rounded-left border-right-0"
                              size="lg"
                            />
                          </div>
                          <div>
                            <Input
                              type="text"
                              name="2"
                              innerRef={confirmCode2Ref}
                              onChange={handleChange}
                              onKeyDown={handleKeyPress}
                              value={confirmCode[1].value}
                              maxLength="1"
                              aria-label="digit 2 of 6"
                              aria-disabled="false"
                              className=" form-control text-center rounded-0"
                              size="lg"
                            />
                          </div>
                          <div>
                            <Input
                              type="text"
                              name="3"
                              innerRef={confirmCode3Ref}
                              onChange={handleChange}
                              onKeyDown={handleKeyPress}
                              value={confirmCode[2].value}
                              maxLength="1"
                              aria-label="digit 3 of 6"
                              aria-disabled="false"
                              className=" form-control text-center rounded-right border-left-0"
                              size="lg"
                            />
                          </div>
                        </div>
                        <div className="m-5">—</div>
                        <div className="d-flex">
                          <div>
                            <Input
                              type="text"
                              name="4"
                              innerRef={confirmCode4Ref}
                              onChange={handleChange}
                              onKeyDown={handleKeyPress}
                              value={confirmCode[3].value}
                              maxLength="1"
                              aria-label="digit 4 of 6"
                              aria-disabled="false"
                              className=" form-control text-center rounded-left border-right-0"
                              size="lg"
                            />
                          </div>
                          <div>
                            <Input
                              type="text"
                              name="5"
                              innerRef={confirmCode5Ref}
                              onChange={handleChange}
                              onKeyDown={handleKeyPress}
                              value={confirmCode[4].value}
                              maxLength="1"
                              aria-label="digit 5 of 6"
                              aria-disabled="false"
                              className=" form-control text-center rounded-0"
                              size="lg"
                            />
                          </div>
                          <div>
                            <Input
                              type="text"
                              name="6"
                              innerRef={confirmCode6Ref}
                              onChange={handleChange}
                              onKeyDown={handleKeyPress}
                              value={confirmCode[5].value}
                              maxLength="1"
                              aria-label="digit 6 of 6"
                              aria-disabled="false"
                              className=" form-control text-center rounded-right border-left-0"
                              size="lg"
                            />
                          </div>
                        </div>
                      </div>
                    </InputGroup>
                    {validateError && showErrorMessage(validateError)}
                    {authSelector?.verifyEmailError && showErrorMessage(authSelector.verifyEmailError.message)}
                  </FormGroup>
                </Col>
              </Row>
              <div>
                <Button type="submit" color="primary" size="xl" className="float-right" block>
                  Continue
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Confirmemail;
