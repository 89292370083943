import { batch, useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { resetAuth } from 'store/reducers/authReducer';
import { resetCurrentMeeting } from 'store/reducers/currentMeetingReducer';
import { resetMeetings } from 'store/reducers/meetingsReducer';
import { resetOrders } from 'store/reducers/ordersReducer';
import { resetAuthOnLogout, resetUser } from 'store/reducers/userReducer';
function useLogout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return function () {
    dispatch(resetAuthOnLogout());
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');

    navigate('/');

    batch(() => {
      dispatch(resetAuth());
      dispatch(resetUser());
      dispatch(resetOrders());
      dispatch(resetMeetings());
      dispatch(resetCurrentMeeting());
    });
  };
}

export default useLogout;
