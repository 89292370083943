const pageRoutesMessageBeforeReload = [
  '/addtype',
  '/addtitle',
  '/addoptions',
  '/addduration',
  '/addprice',
  '/addpricing',
  '/addname',
  '/addavailablehours',
  '/addcover',
];

function addMessageBeforeReload(connect) {
  connect
    ? (window.onbeforeunload = () => {
        return 'You will be redirected to the google connect page';
      })
    : (window.onbeforeunload = () => {
        return 'Are you sure? Changes you made may not be saved';
      });
}

function removeMessageBeforeReload() {
  window.onbeforeunload = null;
}

export function checkNeedMessageBeforeReload(activeRoute, connect) {
  if (pageRoutesMessageBeforeReload.includes(activeRoute)) {
    addMessageBeforeReload(connect);
  } else {
    removeMessageBeforeReload();
  }
}
