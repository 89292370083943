import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from 'services/api';
import { asyncActionsCreator } from 'store/reducersHelper';

export const saveMeeting = createAsyncThunk(
  'currentMeeting/saveMeeting',
  async (meetingData) => await api.saveMeeting(meetingData),
);

export const updateMeeting = createAsyncThunk('currentMeeting/updateMeeting', async (meetingDataForUpdate) =>
  api.updateMeeting(meetingDataForUpdate),
);

export const updateMeetingTeamMemberData = createAsyncThunk(
  'currentMeeting/updateMeetingTeamMemberData',
  async (meetingDataForUpdate) => api.updateMeetingTeamMemberData(meetingDataForUpdate),
);

export const changeAboutMeAndTagline = createAsyncThunk(
  'currentMeeting/changeAboutMeAndTagline',
  async (AboutMeAndTaglineData) => api.changeAboutMeAndTagline(AboutMeAndTaglineData),
);

export const loadMeetingById = createAsyncThunk('currentMeeting/loadMeetingById', async (meetingId) =>
  api.loadMeetingById(meetingId),
);

export const loadMeetingByMeetingRoute = createAsyncThunk(
  'currentMeeting/loadMeetingByMeetingRoute',
  async (meetingRoute) => api.loadMeetingByMeetingRoute({ meetingRoute }),
);
const initialState = {
  isMeetingCreate: false,
  meeting: null,
  allDataSaveOnEdit: true,
};
export const currentMeetingSlice = createSlice({
  name: 'currentMeeting',
  initialState,
  reducers: {
    updateMeetingState: (state, { payload }) => {
      state.meeting = {
        ...state.meeting,
        ...payload,
      };
      state.allDataSaveOnEdit = false;
    },
    setIsMeetingCreate: (state, { payload }) => {
      state.isMeetingCreate = payload;
    },
    setMeetingState: (state, { payload }) => {
      state.meeting = payload;
    },
    setAllDataSaveOnEdit: (state, { payload }) => {
      state.allDataSaveOnEdit = payload;
    },
    resetCurrentMeeting: () => initialState,
  },
  extraReducers: {
    ...asyncActionsCreator(
      saveMeeting,
      'saveMeeting',
      {
        fulfilled: (state, { payload }) => {
          state.isSaveMeetingLoading = false;
          state.meeting = payload.result;
          state.isMeetingCreate = true;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(
      updateMeeting,
      'updateMeeting',
      {
        fulfilled: (state, { payload }) => {
          state.allDataSaveOnEdit = true;
          state.isUpdateMeetingLoading = false;
          state.meeting = payload.meeting;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(
      updateMeetingTeamMemberData,
      'updateMeetingTeamMemberData',
      {
        fulfilled: (state, { payload }) => {
          state.allDataSaveOnEdit = true;
          state.isUpdateMeetingLoading = false;
          state.meeting = payload.meeting;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(changeAboutMeAndTagline, 'changeAboutMeAndTagline', {
      fulfilled: (state) => {
        state.allDataSaveOnEdit = true;
      },
    }),

    ...asyncActionsCreator(
      loadMeetingById,
      'loadMeeting',
      {
        fulfilled: (state, { payload }) => {
          state.isLoadMeetingLoading = false;
          state.meeting = payload.meeting;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(
      loadMeetingByMeetingRoute,
      'loadMeetingByMeetingRoute',
      {
        fulfilled: (state, { payload }) => {
          state.isLoadMeetingByMeetingRouteLoading = false;
          state.meeting = payload.meeting;
        },
      },
      { loadingHandler: true },
    ),
  },
});

export const { updateMeetingState, setAllDataSaveOnEdit, setMeetingState, setIsMeetingCreate, resetCurrentMeeting } =
  currentMeetingSlice.actions;

export default currentMeetingSlice.reducer;
