import { validCountries, validStates } from 'constants/meeting.const';
import { each, omit } from 'lodash';
import isEmpty from 'validator/lib/isEmpty';

export function toUpperCaseFirstLetter(word) {
  return word.substr(0, 1).toUpperCase() + word.slice(1);
}

export function zeroPad(num, places) {
  return String(num).padStart(places, '0');
}

export function createMeetingEmbedCode(shareLink, color, type) {
  const src =
    shareLink && type !== 'all' && type !== 'popup-all'
      ? `${shareLink}`
      : `${shareLink.split('/')[0]}//${shareLink.split('/')[2]}/${shareLink.split('/')[3]}`;

  switch (type) {
    case 'mini':
      return `<iframe src="${src}?type=${type}&color=${color.substring(
        1,
      )}" width="100%" height="520px" frameborder="0" allow="encrypted-media autoplay"></iframe>`;

    case 'full':
      return `<iframe src="${src}?type=${type}&color=${color.substring(
        1,
      )}" width="100%" height="520px" frameborder="0" allow="encrypted-media autoplay"></iframe>`;

    case 'all':
      return `<iframe src="${src}?type=${type}&color=${color.substring(
        1,
      )}" width="100%" height="520px" frameborder="0" allow="encrypted-media autoplay"></iframe>`;

    case 'popup-single':
      return `<button style="position: fixed;bottom: 0;right: 20%;padding: 5px 35px;border: 0px;border-radius: 5px 5px 0 0;background:${color};color: #fff;font-weight: 600;box-shadow: 0 0 1.1rem 0 rgba(136, 152, 170, 0.45);height: 40px;line-height:24px;font-size:17px;z-index:100000;" onclick="document.getElementById('wsPopup').style.display = 'block'">Book A Call With Us</button><div id="wsPopup" style="position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(31, 31, 31, .4);z-index:100001;display:none;"><div style="position:absolute;left:0;right:0;bottom:0;top:0;cursor: pointer;" onclick="document.getElementById('wsPopup').style.display = 'none'"><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" style="position:absolute;top:15px;right:15px;cursor:pointer;fill:#fff;"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg></div><iframe src="${src}?type=full&color=${color.substring(
        1,
      )}" frameborder="0" allow="encrypted-media autoplay" style="width: calc(100% - 78px);height: calc(100vh - 50px);position:absolute;top:50%;left:50%;transform: translate(-50%, -50%);max-width:750px;"></iframe></div>`;

    case 'popup-all':
      return `<button style="position: fixed;bottom: 0;right: 20%;padding: 5px 35px;border: 0px;border-radius: 5px 5px 0 0;background:${color};color: #fff;font-weight: 600;box-shadow: 0 0 1.1rem 0 rgba(136, 152, 170, 0.45);height: 40px;line-height:24px;font-size:17px;z-index:100000;" onclick="document.getElementById('wsPopup').style.display = 'block'">Book A Call With Us</button><div id="wsPopup" style="position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(31, 31, 31, .4);z-index:100001;display:none;"><div style="position:absolute;left:0;right:0;bottom:0;top:0;cursor: pointer;" onclick="document.getElementById('wsPopup').style.display = 'none'"><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" style="position:absolute;top:15px;right:15px;cursor:pointer;fill:#fff;"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg></div><iframe src="${src}?type=full&color=${color.substring(
        1,
      )}" frameborder="0" allow="encrypted-media autoplay" style="width: calc(100% - 78px);height: calc(100vh - 50px);position:absolute;top:50%;left:50%;transform: translate(-50%, -50%);max-width:750px;"></iframe></div>`;

    default:
      return `<iframe src="${src}?type=mini" width="100%" height="520px" frameborder="0"  allow="encrypted-media autoplay"></iframe>`;
  }
}

export function createMeetingBotEmbedCode(shareLink, color, character, textMessage) {
  const src = `${shareLink}?type=bot&color=${color.substring(1)}&img=${character}&text=${textMessage}`;
  return `<div id="wsPopup" style="position:fixed;bottom:0px;left:0px;z-index:1000000;"><iframe id="ws-bot" src="${src}" frameborder="0" allow="encrypted-media autoplay" style="width:470px;height:370px;" onload="close_frame()"></iframe></div><script>function close_frame(){if(!window.should_close){window.should_close=1; document.getElementById("ws-bot").style.width="470px"; document.getElementById("ws-bot").style.height="370px"; document.getElementById("wsPopup").style.bottom="0px";}else if(window.should_close==1){window.should_close=2; document.getElementById("ws-bot").style.width="80px"; document.getElementById("ws-bot").style.height="65px"; document.getElementById("wsPopup").style.bottom="205px";}else if(window.should_close===2){window.should_close=1; document.getElementById("ws-bot").style.width="470px"; document.getElementById("ws-bot").style.height="370px"; document.getElementById("wsPopup").style.bottom="0px";}}</script><style>@media(max-width:500px){#ws-bot{max-width:100%;}}</style>`;
}
export function createCustomFormEmbedCode(
  shareLink,
  color,
  buttonText,
  nameLabel,
  emailLabel,
  border,
  labels,
  meetingCheckoutForm,
) {
  const customFormFields = meetingCheckoutForm ? getFieldsFromCustomForm(meetingCheckoutForm, labels) : '';
  const joinedCustomFormFields = meetingCheckoutForm ? customFormFields.join(' ') : '';
  const chosenColor = color.substring(1);

  // Validation script setup
  let scriptIncludes = `
    const urlPattern = /^(https?:\\/\\/)?([\\w.-]+\\.[\\w]{2,})(\\/[\\w\\/?=#%-]*)*$|^@\\w+$/i;
    const phonePattern = /^[+]?[(]?[0-9]{1,4}[)]?[-\\s./0-9]*$/;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const zipPattern = /^\\d{5}(-\\d{4})?$/;
    const statePattern = [${validStates.map(state => `"${state}"`).join(", ")}];
    const countryPattern = [${validCountries.map(country => `"${country}"`).join(", ")}];

    function validateForm() {
      let isValid = true;
      document.querySelectorAll('.ws-input-field.required').forEach(field => {
    const fieldName = field.id.replace('-field', '');
    const fieldValue = field.value.trim().toLowerCase();
    const validationMessage = document.getElementById(fieldName + '-validation');
    validationMessage.innerHTML = ''; // Clear previous messages
    validationMessage.style.display = 'none';
    if (!fieldValue) {
      validationMessage.innerHTML = '<span>This field is required.</span>';
      validationMessage.style.display = 'block';
      isValid = false;
    } else {
        if (fieldName.includes('email') && !emailPattern.test(fieldValue)) {
        validationMessage.innerHTML = '<span>Invalid email address. Please enter a valid email address.</span>';
        validationMessage.style.display = 'block';
        isValid = false;
      } else if ((fieldName.includes('url') || fieldName.includes('website') || fieldName.includes('linkedin') || fieldName.includes('pinterest') || fieldName.includes('facebook') || fieldName.includes('youtube') || fieldName.includes('instagram') || fieldName.includes('tiktok')) && !urlPattern.test(fieldValue)) {
        validationMessage.innerHTML = '<span>Invalid URL. Please enter a valid URL.</span>';
        validationMessage.style.display = 'block';
        isValid = false;
      } else if (fieldName.includes('number') && !phonePattern.test(fieldValue)) {
        validationMessage.innerHTML = '<span>Invalid phone number. Please enter a valid phone number.</span>';
        validationMessage.style.display = 'block';
        isValid = false;
      } else if (fieldName.includes('state') && !statePattern.includes(fieldValue)) {
        validationMessage.innerHTML = '<span>Invalid state. Please select a valid state.</span>';
        validationMessage.style.display = 'block';
        isValid = false;
      } else if (fieldName.includes('country') && !countryPattern.includes(fieldValue)) {
        validationMessage.innerHTML = '<span>Invalid country. Please select a valid country.</span>';
        validationMessage.style.display = 'block';
        isValid = false;
      }
    }
  });
      return isValid;
    }

    function setField(e, fieldName) {
      const inputValue = e.target.value;
      const iframe = document.getElementById('ws-popup-form');
      const url = new URL(iframe.src);
      const params = new URLSearchParams(url.search);
      params.set(fieldName, inputValue);
      iframe.src = decodeURIComponent(url.origin + url.pathname + '?' + params.toString());
    }

    function onSubmitButtonClick() {
      if (validateForm()) {
        document.getElementById('wsPopup').style.display = 'block';
      } else {
        console.log('Form validation failed.');
      }
    }`;

  return `<div class="ws-form-container">
    <form autocomplete="on" novalidate="">
      <div class="form-item name">
        ${labels === 'labels only' ? `<label for="name-field">${nameLabel || 'Full Name (required)'}</label>` : ''}
        <input id="name-field" required autocomplete="false" class="ws-input-field required"
        ${labels === 'placeholders' ? `placeholder="${nameLabel || 'Enter your full name'}"` : ''}
        type="text" value="" onblur="setField(event, 'name')" name="demoForm">
        <div class="validation-message" id="name-validation"></div>
      </div>
      <div class="form-item email">
        ${labels === 'labels only' ? `<label for="email-field">${emailLabel || 'Business Email (required)'}</label>` : ''}
        <input id="email-field" required autocomplete="false" class="ws-input-field required"
        ${labels === 'placeholders' ? `placeholder="${emailLabel || 'Enter your email address'}"` : ''}
        type="email" value="" onblur="setField(event, 'email')" name="demoForm">
        <div class="validation-message" id="email-validation"></div>
      </div>
      ${joinedCustomFormFields}
    </form>
    <button class="ws-submit-btn" onclick="onSubmitButtonClick()">${buttonText || 'Get A Demo'}</button>
    <p class="ws-privacy-text">By entering your details and signing up, you read and agree to the
      <a href="https://www.weshare.net/terms-and-conditions/">Terms & Conditions</a> and
      <a href="https://www.weshare.net/privacy-policy/">Privacy Policy.</a> of
      <a href="https://www.weshare.net">Weshare</a>
    </p>
  </div>
  <div id="wsPopup" style="position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(31, 31, 31, .4);z-index:100001;display:none;">
    <div style="position:absolute;left:0;right:0;bottom:0;top:0;cursor: pointer;" onclick="document.getElementById('wsPopup').style.display = 'none'">
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" style="position:absolute;top:15px;right:15px;cursor:pointer;fill:#fff;">
        <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
      </svg>
    </div>
    <iframe id="ws-popup-form" src="${shareLink}?type=mini&color=${chosenColor}" frameborder="0" allow="encrypted-media autoplay" ></iframe>
  </div>
  <script>
    ${scriptIncludes}
  </script>
  <style>
    #wsPopup { position: absolute !important;background: none !important; }
    #wsPopup > div { position: fixed !important; background: rgba(31, 31, 31, 0.4) !important; }
    .ws-form-container { max-width: 700px; padding: 20px; box-sizing: border-box; }
    .form-item { margin-bottom: 1.5rem; }
    .form-item label { display: block; width: 100%; margin-bottom: .5rem; color: #000; font-size: 16px; font-weight: 500; font-family: sans-serif; }
    .ws-input-field { display: block; width: 100%; padding: .625rem .75rem; color: #2f3136; background-color: #fafafa; border: 1px solid #dee2e6; border-radius: ${border === 'rounded' ? '.6rem' : '0rem'}; font-size: 1.0625rem; height: calc(2.25em + 1.25rem + 5px); }
    .ws-input-field:focus { border: 1px solid #${chosenColor}; outline: 1px solid #${chosenColor}; }
    .ws-submit-btn { padding: 1rem 1.25rem; font-size: 16px; border: 0; background: #${chosenColor}; color: #fff; font-weight: 600; font-family: sans-serif; width: 100%; cursor: pointer; border-radius: ${border === 'rounded' ? '6rem' : '6px'}; line-height: 1.5; }
    .validation-message { display: none; color: red; font-size: 14px; margin-top: 4px }
    .ws-popup { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(31, 31, 31, .4); z-index: 100001; display: none; width: 100%; height: 100% }
    .ws-popup-close { position:absolute;top:15px;right:15px;cursor:pointer;fill:#fff }
    iframe#ws-popup-form { width: 100% !important; height: 1500px !important; position: relative !important;top: 30px !important;left: 50% !important;transform: translate(-50%, 0%) !important; }
    p.ws-privacy-text { text-align: left; font-size: 14px; margin-top: 1em; font-weight: 400; color: #525f7f; font-family: sans-serif; line-height: 1.5 }
    p.ws-privacy-text a { text-decoration: underline; color: #525f7f }
  </style>`;
}



export function changeGapOnDashOnInput(str) {
  const lastStrEl = str[str.length - 1];
  const prevLastStrEl = str[str.length - 2];

  if (lastStrEl === ' ' && prevLastStrEl !== '-') return str.replaceAll(/\s+/g, '-');
  if (lastStrEl === ' ') return str.trim();
  return str;
}

export function createMeetingRoute(username, meetingSlug) {
  return username && meetingSlug ? `${username}/${meetingSlug.replaceAll(/ /g, '-').toLowerCase()}` : '';
}

export function getSlugFromMeetingRoute(meetingRoute) {
  return meetingRoute?.split('/')[1];
}
export function stripUnwantedSpaces(str) {
  return str
    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
    .toLowerCase()
    .split(' ')
    .join('-');
}

export function getFieldsFromCustomForm(meetingCheckoutForm, labels) {
  let fieldsArray = [];

  meetingCheckoutForm.forEach((field) => {
    const slug = stripUnwantedSpaces(field.title);
    const fieldName = field.title.toLowerCase();

    let fieldHTML = '';
    let inputFieldHTML = '';

    // common input attributes
    const commonAttrs = `
      id="${slug}-field" 
      ${field.isRequired ? 'required' : ''} 
      autocomplete="false" 
      class="ws-input-field ${field.isRequired ? 'required' : ''}"
      onblur="setField(event, '${slug}')"
      name="demoForm"
    `;

    if (labels === 'labels only' && field.type !== 'radio' && field.type !== 'dropdown') {
      fieldHTML += `<label class="field-label">${field.title}</label>`;
    }

    if (field.type === 'text' || field.type === 'number' || field.type === 'textarea') {
      inputFieldHTML = `<input 
          ${commonAttrs}
          type="${field.type}" 
          value="" 
          ${labels === 'placeholders' ? `placeholder="${field.title}"` : ''}>`;
      fieldHTML += inputFieldHTML;

      if (field.isRequired) {
        fieldHTML += `<div class="validation-message" id="${slug}-validation">This field is required. Please type it again.</div>`;
      }

      fieldsArray.push(`<div class="form-item">${fieldHTML}</div>`);
    } else if (field.type === 'radio') {
      let radioOptionsHTML = field.options
        .map(
          (option) =>
            `<label onClick="setField(event, '${slug}')">
          <input type="radio" name="${slug}" value="${option.options}">
          ${option.options}
        </label>`,
        )
        .join('');

      fieldHTML += radioOptionsHTML;

      if (field.isRequired) {
        fieldHTML += `<div class="validation-message" id="${slug}-validation">A ${fieldName} is required. Please type it again.</div>`;
      }

      fieldsArray.push(
        `<div class="form-item ${field.isRequired ? 'required' : ''}"><label class="field-label">${
          field.title
        }</label>${fieldHTML}</div>`,
      );
    } else if (field.type === 'dropdown') {
      const dropdownOptionsHTML = field.options
        .map(
          (option) => `
    <option value="${option.options}" onClick="setField(event, '${slug}')">${option.options}</option>
  `,
        )
        .join('');

      const selectHTML = `
    <select ${commonAttrs}>
      ${dropdownOptionsHTML}
    </select>
  `;

      fieldHTML += selectHTML;
      if (field.isRequired) {
        fieldHTML += `<div class="validation-message" id="${slug}-validation">A ${fieldName} is required. Please select an option.</div>`;
      }

      fieldsArray.push(`<div class="form-item"><label class="field-label">${field.title}</label>${fieldHTML}</div>`);
    }
  });

  return fieldsArray;
}

export function findBasicPackage(meeting) {
  return meeting?.meetingPackages?.find((el) => el.idName === 'basic');
}

export function updateFieldInPackage(packages, packageId, fieldForUpdate, newFieldValue) {
  return packages.map((meetingPackage) => {
    if (meetingPackage.idName === packageId) {
      return {
        ...meetingPackage,
        [fieldForUpdate]: newFieldValue,
      };
    } else {
      return meetingPackage;
    }
  });
}

export function isEmptyIgnoreSpaces(str, config = {}) {
  return isEmpty(str, { ignore_whitespace: true, ...config });
}

export function removeFieldsFromObject(obj, fields) {
  const updatedObj = omit(obj, fields);

  each(updatedObj, function (val, key) {
    if (typeof val === 'object') {
      const res = removeFieldsFromObject(val, fields);
      updatedObj[key] = Array.isArray(val) ? Object.values(res) : res;
    }
  });

  return updatedObj;
}

export function onKeyDownEnterPreventDefault(keyEvent) {
  if (keyEvent.code === 'Enter') keyEvent.preventDefault();
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
