import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from 'services/api'; // Adjust this path as per your project structure.

// Async thunks for note operations
export const fetchNoteById = createAsyncThunk(
  'notes/fetchNoteById',
  async (noteId, { rejectWithValue }) => {
    try {
      const response = await api.fetchNoteById(noteId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchNotesByCustomerId = createAsyncThunk(
  'notes/byCustomer',
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await api.fetchNotesByCustomerId(customerId);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createNote = createAsyncThunk(
  'notes/createNote',
  async (noteData, { rejectWithValue }) => {
    try {
      const response = await api.createNote(noteData);
      return response;
    } catch (error) {
      console.error('Error creating note:', error);
      return rejectWithValue(error.response.data);
    }
  }
);


export const updateNote = createAsyncThunk(
  'notes/updateNote',
  async ({ noteId, updateData }, { rejectWithValue }) => {
    try {
      await api.updateNote(noteId, updateData);
      return { ...updateData, id: noteId };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteNote = createAsyncThunk(
  'notes/deleteNote',
  async (noteId, { rejectWithValue }) => {
    try {
      await api.deleteNote(noteId);
      return noteId;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  notes: [],
  isLoading: false,
  error: null
};

const noteSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    resetNoteError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNoteById.fulfilled, (state, action) => {
        // Optionally find and update one note or add if not exists
        const index = state.notes.findIndex(note => note._id === action.payload.id);
        if (index !== -1) {
          state.notes[index] = action.payload;
        } else {
          state.notes.push(action.payload);
        }
      })
      .addCase(fetchNotesByCustomerId.fulfilled, (state, action) => {
        state.notes = action.payload;
      })
      .addCase(createNote.fulfilled, (state, action) => {
        if (!state.notes) {
          state.notes = [];
        }
        state.notes.push(action.payload);
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        const index = state.notes.findIndex(note => note._id === action.payload.id);
        if (index !== -1) {
          state.notes[index] = {...state.notes[index], ...action.payload};
        }
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.notes = state.notes.filter(note => note._id !== action.payload);
      })
      .addMatcher(
        action => action.type.startsWith('notes/') && action.type.endsWith('/pending'),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        action => action.type.startsWith('notes/') && action.type.endsWith('/fulfilled'),
        (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        action => action.type.startsWith('notes/') && action.type.endsWith('/rejected'),
        (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
        }
      );
  }
});

export const { resetNoteError } = noteSlice.actions;

export default noteSlice.reducer;